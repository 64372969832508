const categories = {
  "Beverages": {
    "Types of Coffee Drinks": [
      "Espresso",
      "Latte",
      "Cappuccino",
      "Americano",
      "Mocha",
      "Macchiato",
      "Flat White",
      "Cold Brew",
      "Affogato",
      "Café au Lait",
    ],
    "Brands of Whiskey": [
      "Jack Daniel's",
      "Jameson",
      "Johnnie Walker",
      "Glenfiddich",
      "Macallan",
      "Wild Turkey",
      "Chivas Regal",
      "Maker's Mark",
      "Bulleit Bourbon",
      "Woodford Reserve",
    ],
    "Types of Tequila": [
      "Blanco",
      "Reposado",
      "Añejo",
      "Extra Añejo",
      "Joven",
      "Cristalino",
      "Mixto",
      "Plata",
      "Gold",
      "Silver",
    ],
    "Liquors": [
      "Vodka",
      "Rum",
      "Gin",
      "Brandy",
      "Cognac",
      "Bourbon",
      "Scotch",
      "Absinthe",
      "Schnapps",
      "Triple Sec",
    ],
    "Beer Brands": [
      "Budweiser",
      "Heineken",
      "Corona",
      "Guinness",
      "Stella Artois",
      "Miller Lite",
      "Coors Light",
      "Samuel Adams",
      "Blue Moon",
      "Dos Equis",
    ],
    "Soda Brands": [
      "Coca-Cola",
      "Pepsi",
      "Sprite",
      "Fanta",
      "Dr Pepper",
      "Mountain Dew",
      "7UP",
      "Sunkist",
      "A&W Root Beer",
      "Mug Root Beer"
    ],
    "Energy Drinks": [
      "Red Bull",
      "Monster",
      "Rockstar",
      "NOS",
      "Bang Energy",
      "Reign",
      "5-Hour Energy",
      "Celsius",
      "Xyience",
      "Full Throttle"
    ],
    "Bottled Water Brands": [
      "Evian",
      "Fiji Water",
      "Dasani",
      "Aquafina",
      "Smartwater",
      "Voss",
      "Perrier",
      "San Pellegrino",
      "Ice Mountain",
      "Poland Spring"
    ],
    "Craft Beer Styles": [
      "IPA (India Pale Ale)",
      "Stout",
      "Porter",
      "Saison",
      "Pilsner",
      "Lager",
      "Sour Ale",
      "Wheat Beer",
      "Belgian Dubbel",
      "Barleywine"
    ],
    "Teas (by type)": [
      "Green Tea",
      "Black Tea",
      "Oolong Tea",
      "White Tea",
      "Rooibos",
      "Chamomile",
      "Peppermint",
      "Chai",
      "Matcha",
      "Earl Grey"
    ],
    "Juice Brands": [
      "Tropicana",
      "Minute Maid",
      "Simply Orange",
      "Ocean Spray",
      "Naked Juice",
      "Bolthouse Farms",
      "Dole",
      "Mott's",
      "Capri Sun",
      "V8"
    ],
    "Whiskey Types": [
      "Bourbon",
      "Scotch",
      "Rye Whiskey",
      "Irish Whiskey",
      "Tennessee Whiskey",
      "Canadian Whisky",
      "Japanese Whisky",
      "Single Malt",
      "Blended Whiskey",
      "Corn Whiskey"
    ],
    "Coffee Brands": [
      "Starbucks",
      "Dunkin'",
      "Lavazza",
      "Peet's Coffee",
      "Nespresso",
      "Keurig",
      "Blue Bottle Coffee",
      "Stumptown Coffee",
      "Death Wish Coffee",
      "Cafe Bustelo"
    ],
    "Non-Dairy Milk Alternatives": [
      "Almond Milk",
      "Oat Milk",
      "Soy Milk",
      "Coconut Milk",
      "Rice Milk",
      "Macadamia Milk",
      "Pea Milk",
      "Cashew Milk",
      "Hemp Milk",
      "Flax Milk"
    ],
    "Cocktail Types": [
      "Margarita",
      "Mojito",
      "Old Fashioned",
      "Martini",
      "Cosmopolitan",
      "Manhattan",
      "Negroni",
      "Whiskey Sour",
      "Mai Tai",
      "Pina Colada"
    ]
  },

  "Technology & Software": {
    "Programming Languages": [
      "JavaScript",
      "Python",
      "Java",
      "C++",
      "Ruby",
      "C#",
      "Go",
      "Swift",
      "PHP",
      "Kotlin",
    ],
    "Social Media Platforms": [
      "Facebook",
      "Instagram",
      "Twitter (X)",
      "LinkedIn",
      "Snapchat",
      "TikTok",
      "Reddit",
      "Pinterest",
      "YouTube",
      "WhatsApp",
    ],
    "Video Game Consoles": [
      "PlayStation 5",
      "Xbox Series X",
      "Nintendo Switch",
      "PlayStation 4",
      "Xbox One",
      "Nintendo Wii",
      "Nintendo 3DS",
      "PlayStation 3",
      "Xbox 360",
      "Nintendo GameCube",
    ],
    "Travel Apps": [
      "Airbnb",
      "TripAdvisor",
      "Skyscanner",
      "Google Maps",
      "Hopper",
      "Expedia",
      "Booking.com",
      "Kayak",
      "Uber",
      "Yelp",
    ],
    "Smartphone Brands": [
      "Apple",
      "Samsung",
      "Google",
      "OnePlus",
      "Xiaomi",
      "Huawei",
      "Sony",
      "LG",
      "Nokia",
      "Motorola",
    ],    
    "Cloud Computing Platforms": [
      "Amazon Web Services (AWS)",
      "Microsoft Azure",
      "Google Cloud Platform (GCP)",
      "IBM Cloud",
      "Oracle Cloud",
      "Alibaba Cloud",
      "DigitalOcean",
      "Salesforce",
      "VMware Cloud",
      "Linode"
    ],
    "Operating Systems": [
      "Windows",
      "macOS",
      "Linux",
      "Ubuntu",
      "Fedora",
      "Chrome OS",
      "Debian",
      "Red Hat Enterprise Linux",
      "FreeBSD",
      "Android"
    ],
    "Web Browsers": [
      "Google Chrome",
      "Mozilla Firefox",
      "Microsoft Edge",
      "Safari",
      "Opera",
      "Brave",
      "Vivaldi",
      "Tor Browser",
      "UC Browser",
      "Internet Explorer"
    ],
    "Databases": [
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "Oracle Database",
      "Microsoft SQL Server",
      "SQLite",
      "Cassandra",
      "Redis",
      "MariaDB",
      "Couchbase"
    ],
    "Mobile Operating Systems": [
      "Android",
      "iOS",
      "Windows Phone",
      "BlackBerry OS",
      "Sailfish OS",
      "Tizen",
      "KaiOS",
      "Palm OS",
      "Symbian",
      "Bada"
    ],
    "DevOps Tools": [
      "Jenkins",
      "Docker",
      "Kubernetes",
      "Ansible",
      "Terraform",
      "GitLab CI",
      "CircleCI",
      "Puppet",
      "Travis CI",
      "Chef"
    ],
    "Version Control Systems": [
      "Git",
      "Subversion (SVN)",
      "Mercurial",
      "Perforce",
      "BitKeeper",
      "TFS (Team Foundation Server)",
      "Bazaar",
      "CVS (Concurrent Versions System)",
      "Plastic SCM",
      "Monotone"
    ],
    "Front-End Frameworks": [
      "React",
      "Angular",
      "Vue.js",
      "Svelte",
      "Ember.js",
      "Backbone.js",
      "Preact",
      "Alpine.js",
      "LitElement",
      "Mithril"
    ],
    "Back-End Frameworks": [
      "Node.js",
      "Django",
      "Flask",
      "Ruby on Rails",
      "Laravel",
      "Spring",
      "Express",
      "ASP.NET",
      "FastAPI",
      "Koa.js"
    ],
    "Content Management Systems (CMS)": [
      "WordPress",
      "Joomla",
      "Drupal",
      "Magento",
      "Shopify",
      "Wix",
      "Squarespace",
      "Ghost",
      "TYPO3",
      "HubSpot CMS"
    ],
    "Cybersecurity Tools": [
      "Wireshark",
      "Metasploit",
      "Nmap",
      "Kali Linux",
      "Snort",
      "Nessus",
      "OpenVAS",
      "Burp Suite",
      "Qualys",
      "Maltego"
    ],
    "API Testing Tools": [
      "Postman",
      "Swagger",
      "SoapUI",
      "Insomnia",
      "Apigee",
      "JMeter",
      "Katalon Studio",
      "Paw",
      "Karate DSL",
      "RestAssured"
    ],
    "Virtualization Platforms": [
      "VMware vSphere",
      "Microsoft Hyper-V",
      "Citrix XenServer",
      "Oracle VM VirtualBox",
      "KVM (Kernel-based Virtual Machine)",
      "Proxmox",
      "OpenStack",
      "Red Hat Virtualization",
      "AWS EC2",
      "Google Compute Engine"
    ],
    "Project Management Tools": [
      "Jira",
      "Trello",
      "Asana",
      "Basecamp",
      "Monday.com",
      "ClickUp",
      "Wrike",
      "Smartsheet",
      "Notion",
      "Teamwork"
    ],
    "Machine Learning Libraries": [
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "Keras",
      "XGBoost",
      "LightGBM",
      "MXNet",
      "Theano",
      "Caffe",
      "CNTK"
    ],
    "(CI/CD) Tools": [
      "GitLab CI",
      "CircleCI",
      "Jenkins",
      "Travis CI",
      "Bamboo",
      "TeamCity",
      "Drone CI",
      "Buddy",
      "Semaphore",
      "GoCD"
    ],
    "Text Editors": [
      "Visual Studio Code",
      "Sublime Text",
      "Atom",
      "Notepad++",
      "Vim",
      "Emacs",
      "Brackets",
      "TextMate",
      "BBEdit",
      "UltraEdit"
    ]
  },
  "Leisure & Recreation": {
    "Ski Resorts": [
      "Aspen, USA",
      "Whistler Blackcomb, Canada",
      "Zermatt, Switzerland",
      "St. Anton, Austria",
      "Niseko, Japan",
      "Vail, USA",
      "Banff, Canada",
      "Chamonix, France",
      "Cortina d'Ampezzo, Italy",
      "Val d'Isère, France",
    ],
  },

  "Entertainment": {
    "Genres of Music": [
      "Rock",
      "Pop",
      "Jazz",
      "Classical",
      "Hip-Hop",
      "Electronic",
      "Country",
      "Blues",
      "Reggae",
      "Metal",
    ],
    "Podcasts": [
      "The Joe Rogan Experience",
      "Serial",
      "Stuff You Should Know",
      "The Daily",
      "Radiolab",
      "Freakonomics Radio",
      "How I Built This",
      "The Tim Ferriss Show",
      "Crime Junkie",
      "My Favorite Murder",
    ],
    "Types of Movies": [
      "Action",
      "Comedy",
      "Drama",
      "Horror",
      "Sci-Fi",
      "Romantic",
      "Thriller",
      "Documentary",
      "Animation",
      "Fantasy",
    ],
    "Netflix Originals": [
      "Stranger Things",
      "The Crown",
      "Ozark",
      "The Witcher",
      "House of Cards",
      "Narcos",
      "Money Heist",
      "Bridgerton",
      "The Queen's Gambit",
      "Black Mirror",
    ],
    "Michael Bay Films": [
      "Transformers",
      "Armageddon",
      "Pearl Harbor",
      "The Rock",
      "Bad Boys",
      "Transformers: Revenge of the Fallen",
      "Transformers: Dark of the Moon",
      "Pain & Gain",
      "13 Hours: The Secret Soldiers of Benghazi",
      "Transformers: Age of Extinction",
    ],
    "Harry Potter Films": [
      "Harry Potter and the Sorcerer's Stone",
      "Harry Potter and the Chamber of Secrets",
      "Harry Potter and the Prisoner of Azkaban",
      "Harry Potter and the Goblet of Fire",
      "Harry Potter and the Order of the Phoenix",
      "Harry Potter and the Half-Blood Prince",
      "Harry Potter and the Deathly Hallows – Part 1",
      "Harry Potter and the Deathly Hallows – Part 2",
    ],
    "Star Wars Films": [
      "Star Wars: Episode IV – A New Hope",
      "Star Wars: Episode V – The Empire Strikes Back",
      "Star Wars: Episode VI – Return of the Jedi",
      "Star Wars: Episode I – The Phantom Menace",
      "Star Wars: Episode II – Attack of the Clones",
      "Star Wars: Episode III – Revenge of the Sith",
      "Star Wars: Episode VII – The Force Awakens",
      "Star Wars: Episode VIII – The Last Jedi",
      "Star Wars: Episode IX – The Rise of Skywalker",
      "Rogue One: A Star Wars Story",
    ],
    "Oscar-Nominated Movies": [
      "The Godfather",
      "Forrest Gump",
      "Schindler's List",
      "The Shawshank Redemption",
      "Titanic",
      "Pulp Fiction",
      "The Lord of the Rings: The Return of the King",
      "Gladiator",
      "La La Land",
      "Parasite",
    ],
    "Animated TV Shows": [
      "The Simpsons",
      "Rick and Morty",
      "Avatar: The Last Airbender",
      "Family Guy",
      "South Park",
      "Bob's Burgers",
      "Futurama",
      "Adventure Time",
      "Archer",
      "Bojack Horseman",
    ],
    "Horror Movies": [
      "The Exorcist",
      "Halloween",
      "A Nightmare on Elm Street",
      "The Shining",
      "Psycho",
      "The Conjuring",
      "It",
      "Get Out",
      "The Texas Chainsaw Massacre",
      "Hereditary",
    ],
    "Romantic Comedies": [
      "When Harry Met Sally",
      "Pretty Woman",
      "10 Things I Hate About You",
      "Notting Hill",
      "Love Actually",
      "The Proposal",
      "You've Got Mail",
      "Sleepless in Seattle",
      "Bridget Jones's Diary",
      "Crazy Rich Asians",
    ],
    "Action Movies": [
      "Die Hard",
      "Mad Max: Fury Road",
      "Gladiator",
      "The Dark Knight",
      "Terminator 2: Judgment Day",
      "John Wick",
      "Lethal Weapon",
      "The Matrix",
      "Indiana Jones: Raiders of the Lost Ark",
      "Kill Bill: Vol. 1",
    ],
    "Sci-Fi Movies": [
      "Blade Runner",
      "The Matrix",
      "Inception",
      "Star Wars: A New Hope",
      "The Terminator",
      "Interstellar",
      "Back to the Future",
      "2001: A Space Odyssey",
      "Jurassic Park",
      "E.T. the Extra-Terrestrial",
    ],
    "80s Movies": [
      "The Breakfast Club",
      "Ferris Bueller's Day Off",
      "E.T. the Extra-Terrestrial",
      "Back to the Future",
      "Ghostbusters",
      "The Goonies",
      "Top Gun",
      "Die Hard",
      "Blade Runner",
      "Indiana Jones: Raiders of the Lost Ark",
    ],
    "Documentaries": [
      "Planet Earth",
      "The Last Dance",
      "Won't You Be My Neighbor?",
      "13th",
      "Free Solo",
      "Making a Murderer",
      "The Social Dilemma",
      "The Cove",
      "Blackfish",
      "Icarus",
    ],
    "Miniseries": [
      "Chernobyl",
      "Band of Brothers",
      "The Queen's Gambit",
      "Sharp Objects",
      "Big Little Lies",
      "The Night Of",
      "Fargo",
      "The Pacific",
      "True Detective",
      "The Undoing",
    ],
    "Streaming Service Originals": [
      "Stranger Things",
      "The Mandalorian",
      "The Crown",
      "The Boys",
      "The Witcher",
      "The Queen's Gambit",
      "Ozark",
      "The Handmaid's Tale",
      "Mindhunter",
      "Narcos",
    ],
    "Award-Winning TV Shows": [
      "Breaking Bad",
      "The Sopranos",
      "The West Wing",
      "Mad Men",
      "Game of Thrones",
      "The Crown",
      "Fleabag",
      "The Marvelous Mrs. Maisel",
      "Schitt's Creek",
      "The Handmaid's Tale",
    ],
    "Historical Dramas": [
      "Braveheart",
      "Gladiator",
      "Schindler's List",
      "12 Years a Slave",
      "The King's Speech",
      "Lincoln",
      "The Imitation Game",
      "Dunkirk",
      "The Last of the Mohicans",
      "The Patriot",
    ],
    "Superhero Movies": [
      "The Dark Knight",
      "Iron Man",
      "Avengers: Endgame",
      "Spider-Man: Into the Spider-Verse",
      "Logan",
      "Wonder Woman",
      "Guardians of the Galaxy",
      "Black Panther",
      "The Avengers",
      "Superman",
    ],
    "Sitcoms": [
      "Friends",
      "The Office",
      "Seinfeld",
      "Parks and Recreation",
      "How I Met Your Mother",
      "Brooklyn Nine-Nine",
      "The Big Bang Theory",
      "Frasier",
      "Cheers",
      "Arrested Development",
    ],
    "Reality TV Shows": [
      "Survivor",
      "The Bachelor",
      "The Amazing Race",
      "Big Brother",
      "Keeping Up with the Kardashians",
      "The Real Housewives",
      "RuPaul's Drag Race",
      "American Idol",
      "Top Chef",
      "Project Runway",
    ],
    "TV Shows from the 90s": [
      "Friends",
      "The X-Files",
      "Buffy the Vampire Slayer",
      "Seinfeld",
      "Fresh Prince of Bel-Air",
      "Twin Peaks",
      "ER",
      "The Simpsons",
      "Frasier",
      "Boy Meets World",
    ],
    "Foreign Films": [
      "Parasite",
      "Amélie",
      "Pan's Labyrinth",
      "City of God",
      "The Lives of Others",
      "Oldboy",
      "Cinema Paradiso",
      "Crouching Tiger, Hidden Dragon",
      "Life is Beautiful",
      "Rashomon",
    ],
    "Nickelodeon TV Shows": [
      "SpongeBob SquarePants",
      "Rugrats",
      "Hey Arnold!",
      "The Fairly OddParents",
      "Avatar: The Last Airbender",
      "Drake & Josh",
      "iCarly",
      "All That",
      "The Adventures of Jimmy Neutron: Boy Genius",
      "Zoey 101",
    ],
    "Disney Movies": [
      "The Lion King",
      "Beauty and the Beast",
      "Aladdin",
      "Frozen",
      "The Little Mermaid",
      "Moana",
      "Mulan",
      "Zootopia",
      "Tangled",
      "Cinderella",
    ],
    "Pixar Movies": [
      "Toy Story",
      "Finding Nemo",
      "The Incredibles",
      "Up",
      "Ratatouille",
      "Monsters, Inc.",
      "WALL-E",
      "Inside Out",
      "Coco",
      "Cars",
    ],
  },

  "Global Cultures & Cuisines": {
    "Types of Cuisine": [
      "Italian",
      "Mexican",
      "Chinese",
      "Japanese",
      "Indian",
      "French",
      "Thai",
      "Greek",
      "Spanish",
      "Korean",
    ],
    "Famous Dishes": [
      "Pizza",
      "Sushi",
      "Tacos",
      "Pasta",
      "Curry",
      "Hamburger",
      "Paella",
      "Pad Thai",
      "Croissant",
      "Kimchi",
    ],
    "Street Foods": [
      "Falafel (Middle East)",
      "Hot Dog (USA)",
      "Takoyaki (Japan)",
      "Arepas (Venezuela)",
      "Banh Mi (Vietnam)",
      "Empanadas (Argentina)",
      "Shawarma (Middle East)",
      "Poutine (Canada)",
      "Churros (Spain)",
      "Samosa (India)"
    ],
    "Traditional Desserts": [
      "Baklava (Turkey)",
      "Tiramisu (Italy)",
      "Gulab Jamun (India)",
      "Churros (Spain)",
      "Macarons (France)",
      "Mochi (Japan)",
      "Tres Leches Cake (Mexico)",
      "Pavlova (Australia/New Zealand)",
      "Knafeh (Middle East)",
      "Banoffee Pie (UK)"
    ],
    "Spices & Herbs": [
      "Cumin",
      "Coriander",
      "Turmeric",
      "Paprika",
      "Cinnamon",
      "Saffron",
      "Basil",
      "Oregano",
      "Rosemary",
      "Sumac"
    ],
    "Beverages by Country": [
      "Sangria (Spain)",
      "Sake (Japan)",
      "Tequila (Mexico)",
      "Whisky (Scotland)",
      "Chai (India)",
      "Mojito (Cuba)",
      "Baijiu (China)",
      "Maté (Argentina)",
      "Kvas (Russia)",
      "Caipirinha (Brazil)"
    ],
    "Types of Noodles": [
      "Ramen (Japan)",
      "Pho (Vietnam)",
      "Spaghetti (Italy)",
      "Lo Mein (China)",
      "Pad Thai (Thailand)",
      "Udon (Japan)",
      "Soba (Japan)",
      "Pappardelle (Italy)",
      "Rice Noodles (China/Thailand)",
      "Fettuccine (Italy)"
    ],
    "Fermented Foods": [
      "Kimchi (Korea)",
      "Sauerkraut (Germany)",
      "Miso (Japan)",
      "Natto (Japan)",
      "Tempeh (Indonesia)",
      "Yogurt (Global)",
      "Kombucha (China)",
      "Pickles (Global)",
      "Sourdough Bread (Global)",
      "Fermented Tofu (China)"
    ],
    "Regional Indian Dishes": [
      "Biryani (Hyderabad)",
      "Rogan Josh (Kashmir)",
      "Dosa (South India)",
      "Vindaloo (Goa)",
      "Butter Chicken (Punjab)",
      "Dhansak (Parsi)",
      "Pav Bhaji (Mumbai)",
      "Sambar (Tamil Nadu)",
      "Pakhala Bhata (Odisha)",
      "Litti Chokha (Bihar)"
    ],
    "Mediterranean Dishes": [
      "Hummus (Middle East)",
      "Moussaka (Greece)",
      "Shakshuka (North Africa)",
      "Tabbouleh (Lebanon)",
      "Dolma (Turkey)",
      "Fattoush (Lebanon)",
      "Spanakopita (Greece)",
      "Feta Salad (Greece)",
      "Baba Ganoush (Middle East)",
      "Kebabs (Turkey)"
    ],
    "Bread Varieties": [
      "Baguette (France)",
      "Naan (India)",
      "Sourdough (USA/Europe)",
      "Focaccia (Italy)",
      "Pita (Middle East)",
      "Chapati (India)",
      "Ciabatta (Italy)",
      "Pretzel (Germany)",
      "Rye Bread (Scandinavia)",
      "Cornbread (USA)"
    ],
    "Traditional Soups": [
      "Tom Yum (Thailand)",
      "Borscht (Ukraine/Russia)",
      "Minestrone (Italy)",
      "Pho (Vietnam)",
      "Gazpacho (Spain)",
      "Chowder (USA)",
      "Avgolemono (Greece)",
      "Miso Soup (Japan)",
      "Menudo (Mexico)",
      "Sinigang (Philippines)"
    ],
    "Iconic Cheese Varieties": [
      "Parmesan (Italy)",
      "Brie (France)",
      "Feta (Greece)",
      "Cheddar (UK)",
      "Gouda (Netherlands)",
      "Camembert (France)",
      "Manchego (Spain)",
      "Gruyère (Switzerland)",
      "Halloumi (Cyprus)",
      "Pecorino (Italy)"
    ],
    "Famous Spicy Dishes": [
      "Vindaloo (India)",
      "Kimchi Jjigae (Korea)",
      "Mapo Tofu (China)",
      "Jerk Chicken (Jamaica)",
      "Sichuan Hotpot (China)",
      "Phaal Curry (India)",
      "Sambal (Indonesia/Malaysia)",
      "Buffalo Wings (USA)",
      "Harissa Chicken (North Africa)",
      "Shakshuka (Middle East/North Africa)"
    ],
    "Tea Varieties": [
      "Darjeeling (India)",
      "Earl Grey (UK)",
      "Matcha (Japan)",
      "Chai (India)",
      "Oolong (China)",
      "Gunpowder Tea (China)",
      "Pu-erh (China)",
      "Sencha (Japan)",
      "Rooibos (South Africa)",
      "Yerba Maté (South America)"
    ],
    "Iconic Fermented Drinks": [
      "Kefir (Eastern Europe)",
      "Kvass (Russia)",
      "Kombucha (China)",
      "Chicha (Peru)",
      "Mead (Global)",
      "Sima (Finland)",
      "Pulque (Mexico)",
      "Makgeolli (Korea)",
      "Tepache (Mexico)",
      "Bouza (Egypt)"
    ],
    "Grain-Based Dishes": [
      "Risotto (Italy)",
      "Couscous (North Africa)",
      "Polenta (Italy)",
      "Paella (Spain)",
      "Bulgur Wheat Salad (Middle East)",
      "Congee (China)",
      "Jollof Rice (West Africa)",
      "Pilaf (Middle East/Central Asia)",
      "Tteok (Korea)",
      "Barley Porridge (Scandinavia)"
    ],
    "Flatbreads of the World": [
      "Tortilla (Mexico)",
      "Lefse (Norway)",
      "Injera (Ethiopia)",
      "Roti (India)",
      "Lavash (Armenia)",
      "Matzo (Jewish Cuisine)",
      "Arepa (Venezuela)",
      "Paratha (India)",
      "Khachapuri (Georgia)",
      "Msemen (Morocco)"
    ],
    "Festive Foods": [
      "Panettone (Italy)",
      "Stollen (Germany)",
      "Mooncake (China)",
      "Roast Turkey (USA - Thanksgiving)",
      "King Cake (France/USA)",
      "Sufganiyot (Israel - Hanukkah)",
      "Tamales (Mexico - Christmas)",
      "Mochi (Japan - New Year)",
      "Haggis (Scotland - Burns Night)",
      "Kransekake (Norway - Weddings/Christmas)"
    ],
    "Iconic Salads": [
      "Caesar Salad (USA)",
      "Tabbouleh (Lebanon)",
      "Caprese Salad (Italy)",
      "Nicoise Salad (France)",
      "Greek Salad (Greece)",
      "Cobb Salad (USA)",
      "Waldorf Salad (USA)",
      "Som Tam (Thailand)",
      "Fattoush (Middle East)",
      "Salade Lyonnaise (France)"
    ],
    "Popular Dips & Spreads": [
      "Hummus (Middle East)",
      "Guacamole (Mexico)",
      "Baba Ganoush (Middle East)",
      "Tzatziki (Greece)",
      "Salsa (Mexico)",
      "Raita (India)",
      "Aioli (France)",
      "Pesto (Italy)",
      "Skordalia (Greece)",
      "Muhammara (Middle East)"
    ]

  },

  "Transportation & Travel": {
    "Hotel Chains": [
        "Marriott",
        "Hilton",
        "Hyatt",
        "InterContinental",
        "Sheraton",
        "Radisson",
        "Holiday Inn",
        "Four Seasons",
        "Best Western",
        "Wyndham"
      ],
    "Modes of Transportation": [
      "Car",
      "Bicycle",
      "Train",
      "Airplane",
      "Bus",
      "Motorcycle",
      "Boat",
      "Tram",
      "Subway",
      "Walking",
    ],
    "Motorcycle Brands": [
      "Harley-Davidson",
      "Ducati",
      "Yamaha",
      "Kawasaki",
      "Honda",
      "BMW Motorrad",
      "Suzuki",
      "Triumph",
      "Royal Enfield",
      "Indian Motorcycle",
    ],

    "Vacation Destinations": [
      "Hawaii",
      "Paris",
      "New York City",
      "Tokyo",
      "Sydney",
      "Rome",
      "Cancun",
      "London",
      "Bali",
      "Dubai",
    ],
    "Adventure Travel Destinations": [
      "Machu Picchu, Peru",
      "Great Barrier Reef, Australia",
      "Patagonia, Argentina/Chile",
      "Banff National Park, Canada",
      "Mount Kilimanjaro, Tanzania",
      "Galápagos Islands, Ecuador",
      "Iceland's Ring Road",
      "The Alps, Europe",
      "Grand Canyon, USA",
      "Antarctica",
    ],
    "Budget Travel Destinations": [
      "Thailand",
      "Vietnam",
      "Portugal",
      "Mexico",
      "Greece",
      "India",
      "Indonesia",
      "Romania",
      "Turkey",
      "Colombia",
    ],
    "Exotic Vacation Spots": [
      "Bora Bora, French Polynesia",
      "Maldives",
      "Santorini, Greece",
      "Bali, Indonesia",
      "Fiji",
      "Seychelles",
      "Maui, Hawaii",
      "Phuket, Thailand",
      "Marrakech, Morocco",
      "Cape Town, South Africa",
    ],
    "Road Trip Destinations": [
      "Route 66, USA",
      "Pacific Coast Highway, USA",
      "Great Ocean Road, Australia",
      "Ring Road, Iceland",
      "Amalfi Coast, Italy",
      "Garden Route, South Africa",
      "North Coast 500, Scotland",
      "Cabot Trail, Canada",
      "Atlantic Road, Norway",
      "Causeway Coastal Route, Northern Ireland",
    ],
    "Historic Landmarks": [
      "Eiffel Tower, France",
      "Colosseum, Italy",
      "Great Wall of China",
      "Machu Picchu, Peru",
      "Taj Mahal, India",
      "Statue of Liberty, USA",
      "Stonehenge, UK",
      "Pyramids of Giza, Egypt",
      "Acropolis, Greece",
      "Christ the Redeemer, Brazil",
    ],

    "Luxury Car Brands": [
      "Mercedes-Benz",
      "BMW",
      "Audi",
      "Lexus",
      "Porsche",
      "Jaguar",
      "Bentley",
      "Rolls-Royce",
      "Maserati",
      "Tesla",
    ],
    "Car Rental Companies": [
      "Hertz",
      "Avis",
      "Enterprise",
      "Budget",
      "National",
      "Alamo",
      "Thrifty",
      "Sixt",
      "Dollar",
      "Europcar",
    ],
    "Scenic Train Routes": [
      "Rocky Mountaineer, Canada",
      "Glacier Express, Switzerland",
      "Trans-Siberian Railway, Russia",
      "The Ghan, Australia",
      "Belmond Royal Scotsman, Scotland",
      "West Highland Line, Scotland",
      "Flam Railway, Norway",
      "Bernina Express, Switzerland/Italy",
      "Jacobite Steam Train, Scotland",
      "Durango & Silverton Narrow Gauge Railroad, USA",
    ],
    "Public Transportation Systems": [
      "New York City Subway",
      "London Underground",
      "Paris Métro",
      "Tokyo Metro",
      "Berlin U-Bahn",
      "Toronto TTC",
      "San Francisco BART",
      "Chicago 'L'",
      "Singapore MRT",
      "Hong Kong MTR",
    ],
    "Train Services": [
        "Amtrak",
        "Eurostar",
        "Shinkansen",
        "TGV",
        "Deutsche Bahn",
        "Thalys",
        "Via Rail",
        "Indian Railways",
        "Renfe",
        "Trans-Siberian Railway"
      ]

  },

  "Luxury & Lifestyle": {
    "Watch Brands": [
      "Rolex",
      "Omega",
      "Tag Heuer",
      "Casio",
      "Seiko",
      "Patek Philippe",
      "Breitling",
      "Audemars Piguet",
      "Tissot",
      "Fossil",
    ],
    "Shampoo Brands": [
      "Pantene",
      "Head & Shoulders",
      "Garnier Fructis",
      "Herbal Essences",
      "TRESemmé",
      "Dove",
      "Aussie",
      "OGX",
      "L'Oréal Paris",
      "Suave",
    ],
    "Chocolate Brands": [
      "Hershey's",
      "Cadbury",
      "Lindt",
      "Ghirardelli",
      "Godiva",
      "Toblerone",
      "Ferrero Rocher",
      "Nestlé",
      "Mars",
      "Milka",
    ],
    "Luxury Hotel Chains": [
      "Four Seasons",
      "Ritz-Carlton",
      "St. Regis",
      "Mandarin Oriental",
      "Aman Resorts",
      "Bulgari Hotels",
      "Rosewood Hotels",
      "Six Senses",
      "Waldorf Astoria",
      "Fairmont"
    ],
    "Premium Sneaker Brands": [
      "Nike Air Jordan",
      "Yeezy",
      "Balenciaga",
      "Off-White",
      "Golden Goose",
      "Common Projects",
      "Lanvin",
      "Alexander McQueen",
      "Gucci Ace",
      "Maison Margiela"
    ],
    "Luxury Luggage Brands": [
      "Louis Vuitton",
      "Rimowa",
      "Globe-Trotter",
      "Tumi",
      "Bric's",
      "Montblanc",
      "Samsonite Black Label",
      "Goyard",
      "Hartmann",
      "Carl Friedrik"
    ],
    "High-End Jewelry Brands": [
      "Tiffany & Co.",
      "Cartier",
      "Harry Winston",
      "Van Cleef & Arpels",
      "Bvlgari",
      "Chopard",
      "Graff",
      "Piaget",
      "David Yurman",
      "Mikimoto"
    ],
    "Designer Handbag Brands": [
      "Hermès",
      "Chanel",
      "Louis Vuitton",
      "Gucci",
      "Dior",
      "Fendi",
      "Prada",
      "Saint Laurent",
      "Balenciaga",
      "Givenchy"
    ],
    "Luxury Eyewear Brands": [
      "Cartier",
      "Barton Perreira",
      "Dita",
      "Mykita",
      "Gentle Monster",
      "Linda Farrow",
      "Thom Browne",
      "Jacques Marie Mage",
      "Silhouette",
      "IC! Berlin"
    ],
    "Luxury Bed Linen Brands": [
      "Frette",
      "Sferra",
      "Matouk",
      "Anichini",
      "Ralph Lauren Home",
      "Boll & Branch",
      "Pratesi",
      "Peacock Alley",
      "The White Company",
      "Yves Delorme"
    ],
    "Luxury Perfume Houses": [
      "Maison Francis Kurkdjian",
      "Amouage",
      "Penhaligon's",
      "Clive Christian",
      "Roja Parfums",
      "Frédéric Malle",
      "By Kilian",
      "Serge Lutens",
      "Guerlain",
      "Xerjoff"
    ],
    "Luxury Tea Brands": [
      "TWG Tea",
      "Fortnum & Mason",
      "Mariage Frères",
      "Harney & Sons",
      "Teavana",
      "Ladurée",
      "Kusmi Tea",
      "The Republic of Tea",
      "Bellocq Tea Atelier",
      "Whittard of Chelsea"
    ],
    "Luxury Furniture Brands": [
      "Roche Bobois",
      "Poltrona Frau",
      "B&B Italia",
      "Fendi Casa",
      "Boca do Lobo",
      "Cassina",
      "Minotti",
      "Flexform",
      "Edra",
      "Molteni & C"
    ],
    "High-End Fashion Designers": [
      "Gucci",
      "Chanel",
      "Louis Vuitton",
      "Prada",
      "Versace",
      "Hermès",
      "Dior",
      "Yves Saint Laurent",
      "Balenciaga",
      "Fendi"
    ],
    "Luxury Skincare Brands": [
      "La Mer",
      "Drunk Elephant",
      "SK-II",
      "Sisley Paris",
      "Tatcha",
      "La Prairie",
      "Sunday Riley",
      "Shiseido",
      "Chantecaille",
      "Omorovicza"
    ],
    "Premium Fragrance Brands": [
      "Tom Ford",
      "Chanel",
      "Dior",
      "Creed",
      "Jo Malone",
      "Byredo",
      "Le Labo",
      "Yves Saint Laurent",
      "Gucci",
      "Armani Privé"
    ],
    "High-End Alcohol Brands": [
      "Dom Pérignon",
      "Louis Roederer Cristal",
      "Macallan",
      "Hennessy XO",
      "Patrón",
      "Grey Goose",
      "Johnnie Walker Blue Label",
      "Rémy Martin Louis XIII",
      "Belvedere Vodka",
      "Krug Champagne"
    ],
    "Designer Shoe Brands": [
      "Christian Louboutin",
      "Manolo Blahnik",
      "Jimmy Choo",
      "Salvatore Ferragamo",
      "Gucci",
      "Prada",
      "Balenciaga",
      "Gianvito Rossi",
      "Saint Laurent",
      "Valentino"
    ],
    "Luxury Sunglasses Brands": [
      "Ray-Ban",
      "Maui Jim",
      "Persol",
      "Oliver Peoples",
      "Tom Ford",
      "Gucci",
      "Prada",
      "Dior",
      "Céline",
      "Chanel"
    ]

  },

  "Animals & Nature": {
    "Animals": [
        "Lion",
        "Elephant",
        "Tiger",
        "Giraffe",
        "Kangaroo",
        "Panda",
        "Dolphin",
        "Eagle",
        "Penguin",
        "Zebra"
      ],
    "Pets": [
      "Dog",
      "Cat",
      "Hamster",
      "Rabbit",
      "Fish",
      "Parrot",
      "Guinea Pig",
      "Turtle",
      "Ferret",
      "Chinchilla",
    ],
    "Types of Birds": [
      "Sparrow",
      "Pigeon",
      "Robin",
      "Blue Jay",
      "Cardinal",
      "Crow",
      "Dove",
      "Owl",
      "Eagle",
      "Hawk",
    ],
    "Types of Fish": [
      "Goldfish",
      "Betta Fish",
      "Guppy",
      "Angelfish",
      "Tetra",
      "Molly",
      "Cichlid",
      "Barb",
      "Catfish",
      "Plecostomus",
    ],
    "Types of Reptiles": [
      "Snake",
      "Lizard",
      "Turtle",
      "Crocodile",
      "Alligator",
      "Iguana",
      "Chameleon",
      "Gecko",
      "Skink",
      "Monitor",
    ],
    "Types of Mammals": [
      "Dog",
      "Cat",
      "Elephant",
      "Lion",
      "Tiger",
      "Bear",
      "Whale",
      "Dolphin",
      "Kangaroo",
      "Giraffe",
    ],
    "Types of Insects": [
      "Ant",
      "Bee",
      "Butterfly",
      "Beetle",
      "Dragonfly",
      "Grasshopper",
      "Mosquito",
      "Moth",
      "Wasp",
      "Cockroach",
    ],
    "Types of Trees": [
      "Oak",
      "Maple",
      "Pine",
      "Birch",
      "Willow",
      "Cherry",
      "Elm",
      "Cedar",
      "Ash",
      "Redwood",
    ],
    "Types of Flowers": [
      "Rose",
      "Daisy",
      "Tulip",
      "Sunflower",
      "Lily",
      "Orchid",
      "Carnation",
      "Hydrangea",
      "Daffodil",
      "Peony",
    ],
    "Endangered Species": [
      "Amur Leopard",
      "Javan Rhino",
      "Vaquita",
      "Mountain Gorilla",
      "Sumatran Orangutan",
      "Hawksbill Turtle",
      "Saola",
      "Black Rhino",
      "Snow Leopard",
      "Green Sea Turtle"
  ],
  "Oceanic Mammals": [
      "Blue Whale",
      "Orca",
      "Dugong",
      "Humpback Whale",
      "Sperm Whale",
      "Narwhal",
      "Beluga Whale",
      "Sea Otter",
      "Manatee",
      "Dolphin"
  ],
  "Desert Animals": [
      "Fennec Fox",
      "Camel",
      "Jerboa",
      "Gila Monster",
      "Sidewinder",
      "Meerkat",
      "Scorpion",
      "Dromedary",
      "Thorny Devil",
      "Oryx"
  ],
  "Tropical Birds": [
      "Scarlet Macaw",
      "Toucan",
      "Harpy Eagle",
      "Quetzal",
      "Blue-and-Yellow Macaw",
      "Hornbill",
      "Crowned Pigeon",
      "Sun Conure",
      "Turaco",
      "Cockatoo"
  ],
  "Freshwater Fish": [
      "Rainbow Trout",
      "Catfish",
      "Bass",
      "Pike",
      "Perch",
      "Walleye",
      "Carp",
      "Tilapia",
      "Sturgeon",
      "Salmon"
  ],
  "Nocturnal Animals": [
      "Bat",
      "Aardvark",
      "Owl",
      "Hedgehog",
      "Leopard Gecko",
      "Raccoon",
      "Skunk",
      "Opossum",
      "Nightjar",
      "Bush Baby"
  ],
  "Tundra Animals": [
      "Arctic Fox",
      "Polar Bear",
      "Caribou",
      "Snowy Owl",
      "Musk Ox",
      "Lemming",
      "Arctic Hare",
      "Ptarmigan",
      "Ermine",
      "Walrus"
  ],
  "Rainforest Plants": [
      "Kapok Tree",
      "Bromeliads",
      "Orchids",
      "Rattan Palm",
      "Heliconia",
      "Rubber Tree",
      "Banana Tree",
      "Balsa Tree",
      "Cacao Tree",
      "Ferns"
  ],
  "Endangered Birds": [
      "Kakapo",
      "California Condor",
      "Spix's Macaw",
      "Philippine Eagle",
      "Northern Bald Ibis",
      "Forest Owlet",
      "Great Indian Bustard",
      "Imperial Amazon",
      "Golden-Cheeked Warbler",
      "Spoon-billed Sandpiper"
  ]


  },

  "Science & Knowledge": {
    "Types of Math": [
      "Algebra",
      "Geometry",
      "Calculus",
      "Trigonometry",
      "Statistics",
      "Probability",
      "Number Theory",
      "Linear Algebra",
      "Differential Equations",
      "Discrete Mathematics",
    ],
    "Branches of Science": [
      "Physics",
      "Chemistry",
      "Biology",
      "Astronomy",
      "Geology",
      "Ecology",
      "Mathematics",
      "Computer Science",
      "Psychology",
      "Anthropology"
    ],
    "Famous Scientists": [
      "Albert Einstein",
      "Isaac Newton",
      "Marie Curie",
      "Charles Darwin",
      "Galileo Galilei",
      "Nikola Tesla",
      "Stephen Hawking",
      "Louis Pasteur",
      "Rosalind Franklin",
      "Carl Sagan"
    ],
    "Scientific Theories": [
      "Theory of Relativity",
      "Quantum Mechanics",
      "Evolution by Natural Selection",
      "Big Bang Theory",
      "Plate Tectonics",
      "Germ Theory",
      "Heliocentrism",
      "General Relativity",
      "String Theory",
      "Chaos Theory"
    ],
    "Inventions & Discoveries": [
      "Electricity",
      "Penicillin",
      "The Printing Press",
      "The Telephone",
      "The Internet",
      "DNA Structure",
      "Vaccination",
      "The Steam Engine",
      "Radioactivity",
      "Light Bulb"
    ],
    "Fields of Engineering": [
      "Mechanical Engineering",
      "Civil Engineering",
      "Electrical Engineering",
      "Chemical Engineering",
      "Aerospace Engineering",
      "Biomedical Engineering",
      "Computer Engineering",
      "Environmental Engineering",
      "Structural Engineering",
      "Industrial Engineering"
    ],
    "Mathematical Concepts": [
      "Calculus",
      "Algebra",
      "Geometry",
      "Probability Theory",
      "Number Theory",
      "Topology",
      "Statistics",
      "Game Theory",
      "Set Theory",
      "Combinatorics"
    ],
    "Elements of the Periodic Table": [
      "Hydrogen",
      "Oxygen",
      "Carbon",
      "Helium",
      "Iron",
      "Gold",
      "Uranium",
      "Silver",
      "Nitrogen",
      "Calcium"
    ],
    "Scientific Instruments": [
      "Microscope",
      "Telescope",
      "Spectrometer",
      "Thermometer",
      "Centrifuge",
      "Oscilloscope",
      "Seismograph",
      "Mass Spectrometer",
      "Particle Accelerator",
      "Barometer"
    ],
    "Space Exploration Missions": [
      "Apollo 11",
      "Voyager 1",
      "Curiosity Rover",
      "Hubble Space Telescope",
      "International Space Station (ISS)",
      "Mars Pathfinder",
      "Galileo Mission",
      "New Horizons",
      "Cassini-Huygens",
      "James Webb Space Telescope"
    ],
    "Branches of Biology": [
      "Zoology",
      "Botany",
      "Microbiology",
      "Genetics",
      "Ecology",
      "Evolutionary Biology",
      "Molecular Biology",
      "Marine Biology",
      "Cell Biology",
      "Immunology"
    ],
    "Famous Experiments": [
      "Double-Slit Experiment",
      "Milgram Experiment",
      "Pavlov’s Dogs",
      "Stanford Prison Experiment",
      "Hershey-Chase Experiment",
      "Michelson-Morley Experiment",
      "Cavendish Experiment",
      "Young’s Interference Experiment",
      "Griffith's Experiment",
      "Eddington’s Eclipse Experiment"
    ],
    "Philosophy of Science Concepts": [
      "Falsifiability",
      "Occam’s Razor",
      "Paradigm Shift",
      "Empiricism",
      "Scientific Realism",
      "Instrumentalism",
      "Positivism",
      "Deductive Reasoning",
      "Inductive Reasoning",
      "Reductionism"
    ],
    "Nobel Prize-Winning Discoveries": [
      "Theory of Relativity",
      "CRISPR Gene Editing",
      "Quantum Electrodynamics",
      "Discovery of Insulin",
      "Photoelectric Effect",
      "Structure of DNA",
      "Discovery of Penicillin",
      "Higgs Boson",
      "Gravitational Waves",
      "Radioactivity"
    ],
    "Branches of Physics": [
      "Classical Mechanics",
      "Quantum Mechanics",
      "Thermodynamics",
      "Electromagnetism",
      "Nuclear Physics",
      "Particle Physics",
      "Astrophysics",
      "Optics",
      "Plasma Physics",
      "Condensed Matter Physics"
    ],
    "Human Senses": [
      "Sight",
      "Hearing",
      "Touch",
      "Taste",
      "Smell",
      "Proprioception",
      "Thermoception",
      "Nociception",
      "Equilibrioception",
      "Chronoception"
    ],
    "Famous Mathematicians": [
      "Leonhard Euler",
      "Carl Friedrich Gauss",
      "Pythagoras",
      "Euclid",
      "Alan Turing",
      "Isaac Newton",
      "Srinivasa Ramanujan",
      "John von Neumann",
      "Pierre-Simon Laplace",
      "Évariste Galois"
    ],
    "Branches of Chemistry": [
      "Organic Chemistry",
      "Inorganic Chemistry",
      "Physical Chemistry",
      "Biochemistry",
      "Analytical Chemistry",
      "Theoretical Chemistry",
      "Polymer Chemistry",
      "Environmental Chemistry",
      "Medicinal Chemistry",
      "Quantum Chemistry"
    ],
    "Famous Equations": [
      "E=mc²",
      "Schrödinger’s Equation",
      "Pythagorean Theorem",
      "Maxwell’s Equations",
      "Navier-Stokes Equation",
      "Euler’s Identity",
      "The Drake Equation",
      "Ohm’s Law",
      "Boltzmann’s Equation",
      "Heisenberg’s Uncertainty Principle"
    ],
    "Types of Energy": [
      "Kinetic Energy",
      "Potential Energy",
      "Thermal Energy",
      "Nuclear Energy",
      "Electrical Energy",
      "Mechanical Energy",
      "Chemical Energy",
      "Radiant Energy",
      "Sound Energy",
      "Gravitational Energy"
    ],
    "Scientific Laws": [
      "Newton’s Laws of Motion",
      "Laws of Thermodynamics",
      "Law of Conservation of Mass",
      "Law of Conservation of Energy",
      "Coulomb’s Law",
      "Boyle’s Law",
      "Kepler’s Laws of Planetary Motion",
      "Charles’s Law",
      "Avogadro’s Law",
      "Faraday’s Law"
    ],
    "Quantum Mechanics Concepts": [
      "Wave-Particle Duality",
      "Quantum Entanglement",
      "Superposition",
      "Heisenberg Uncertainty Principle",
      "Schrodinger's Cat",
      "Quantum Tunneling",
      "Quantum Decoherence",
      "Spin",
      "Quantum Teleportation",
      "Bell's Theorem"
    ],
    "Branches of Neuroscience": [
      "Cognitive Neuroscience",
      "Behavioral Neuroscience",
      "Molecular Neuroscience",
      "Developmental Neuroscience",
      "Neuroimaging",
      "Neurophysiology",
      "Neuropsychology",
      "Computational Neuroscience",
      "Neuropathology",
      "Social Neuroscience"
    ],
    "Types of Scientific Studies": [
      "Double-Blind Study",
      "Longitudinal Study",
      "Cross-Sectional Study",
      "Case-Control Study",
      "Cohort Study",
      "Meta-Analysis",
      "Randomized Controlled Trial (RCT)",
      "Observational Study",
      "Qualitative Study",
      "Experimental Study"
    ],
    "Famous Space Telescopes": [
      "Hubble Space Telescope",
      "James Webb Space Telescope",
      "Spitzer Space Telescope",
      "Kepler Space Telescope",
      "Chandra X-Ray Observatory",
      "Gaia Space Observatory",
      "WISE (Wide-Field Infrared Survey Explorer)",
      "Fermi Gamma-ray Space Telescope",
      "Herschel Space Observatory",
      "Planck Space Observatory"
    ],
    "Genetics Concepts": [
      "Mendelian Inheritance",
      "Gene Expression",
      "Genetic Mutation",
      "Epigenetics",
      "CRISPR-Cas9",
      "Gene Therapy",
      "Alleles",
      "Polygenic Inheritance",
      "Homozygous and Heterozygous",
      "Genetic Recombination"
    ],
    "Subfields of Artificial Intelligence": [
      "Machine Learning",
      "Natural Language Processing",
      "Computer Vision",
      "Reinforcement Learning",
      "Expert Systems",
      "Robotics",
      "Speech Recognition",
      "Swarm Intelligence",
      "Genetic Algorithms",
      "Fuzzy Logic"
    ],
    "Branches of Anthropology": [
      "Cultural Anthropology",
      "Biological Anthropology",
      "Archaeology",
      "Linguistic Anthropology",
      "Forensic Anthropology",
      "Paleoanthropology",
      "Ethnography",
      "Medical Anthropology",
      "Environmental Anthropology",
      "Visual Anthropology"
    ],
    "Famous Scientific Constants": [
      "Speed of Light (c)",
      "Planck’s Constant (h)",
      "Gravitational Constant (G)",
      "Boltzmann Constant (k)",
      "Avogadro's Number (N_A)",
      "Gas Constant (R)",
      "Elementary Charge (e)",
      "Permittivity of Free Space (ε₀)",
      "Fine-Structure Constant (α)",
      "Magnetic Constant (μ₀)"
    ],
    "Theoretical Physics Concepts": [
      "Multiverse Theory",
      "Grand Unified Theory",
      "String Theory",
      "M-Theory",
      "Supersymmetry",
      "Holographic Principle",
      "Quantum Gravity",
      "Inflationary Universe Theory",
      "AdS/CFT Correspondence",
      "Loop Quantum Gravity"
    ],
    "Oceanography Branches": [
      "Biological Oceanography",
      "Chemical Oceanography",
      "Physical Oceanography",
      "Geological Oceanography",
      "Paleoceanography",
      "Marine Geophysics",
      "Ocean Dynamics",
      "Marine Ecology",
      "Fisheries Oceanography",
      "Marine Biogeochemistry"
    ]
  },

  "Historical & Mythological": {
    "Ancient Civilizations": [
      "Mesopotamia",
      "Ancient Egypt",
      "Indus Valley",
      "Ancient Greece",
      "Ancient Rome",
      "Maya",
      "Aztec",
      "Inca",
      "Babylon",
      "Persia",
    ],
    "Deities": [
      "Zeus",
      "Odin",
      "Ra",
      "Vishnu",
      "Shiva",
      "Hera",
      "Thor",
      "Isis",
      "Anubis",
      "Apollo",
    ],
    "Greek Gods": [
      "Zeus",
      "Hera",
      "Poseidon",
      "Demeter",
      "Athena",
      "Apollo",
      "Artemis",
      "Ares",
      "Aphrodite",
      "Hephaestus",
    ],
    "Ancient Architectural Wonders": [
      "Great Pyramid of Giza",
      "Hanging Gardens of Babylon",
      "Colossus of Rhodes",
      "Temple of Artemis",
      "Mausoleum at Halicarnassus",
      "Lighthouse of Alexandria",
      "Stonehenge",
      "Machu Picchu",
      "Petra",
      "Angkor Wat"
    ],
    "Legendary Swords": [
      "Excalibur",
      "Gram (Sword of Sigurd)",
      "Joyeuse",
      "Kusanagi-no-Tsurugi",
      "Sword of Goujian",
      "Crocea Mors",
      "Zulfiqar",
      "Tizona",
      "Hofund",
      "Arondight"
    ],
    "Famous Conquerors": [
      "Alexander the Great",
      "Genghis Khan",
      "Julius Caesar",
      "Napoleon Bonaparte",
      "Attila the Hun",
      "William the Conqueror",
      "Hannibal Barca",
      "Cyrus the Great",
      "Tamerlane",
      "Saladin"
    ],
    "Mythical Beasts": [
      "Fenrir",
      "Nemean Lion",
      "Qilin",
      "Basilisk",
      "Leviathan",
      "Jörmungandr",
      "Roc",
      "Chupacabra",
      "Garuda",
      "Manticore"
    ],
    "Ancient Empires": [
      "Akkadian Empire",
      "Hittite Empire",
      "Assyrian Empire",
      "Minoan Civilization",
      "Achaemenid Empire",
      "Byzantine Empire",
      "Carthaginian Empire",
      "Gupta Empire",
      "Kushite Empire",
      "Sassanid Empire"
    ],
    "Underworld Deities": [
      "Hades",
      "Pluto",
      "Osiris",
      "Hel",
      "Anubis",
      "Ereshkigal",
      "Yama",
      "Persephone",
      "Nergal",
      "Mictlantecuhtli"
    ],
    "Historical Mathematicians": [
      "Euclid",
      "Pythagoras",
      "Archimedes",
      "Hypatia",
      "Aryabhata",
      "Al-Khwarizmi",
      "Leonhard Euler",
      "Carl Friedrich Gauss",
      "Srinivasa Ramanujan",
      "Johannes Kepler"
    ],
    "Fabled Creatures of the Sea": [
      "Kraken",
      "Mermaids",
      "Leviathan",
      "Ceto",
      "Ningyo",
      "Aspidochelone",
      "Scylla",
      "Sirens",
      "Charybdis",
      "Abaia"
    ],
    "Legendary Kings": [
      "Gilgamesh",
      "King Arthur",
      "King Solomon",
      "Romulus",
      "Beowulf",
      "King Midas",
      "Agamemnon",
      "Cnut the Great",
      "King David",
      "King Minos"
    ],
    "Ancient Warrior Classes": [
      "Samurai",
      "Spartan Hoplite",
      "Mongol Archer",
      "Nubian Archer",
      "Viking Berserker",
      "Aztec Jaguar Warrior",
      "Roman Legionary",
      "Zande Warrior",
      "Persian Immortal",
      "Hoplite"
    ],
    "Mythical Creatures": [
      "Minotaur",
      "Phoenix",
      "Hydra",
      "Cerberus",
      "Griffin",
      "Chimera",
      "Dragon",
      "Pegasus",
      "Sphinx",
      "Kraken"
    ],
    "Legendary Heroes": [
      "Hercules",
      "Achilles",
      "Perseus",
      "Odysseus",
      "King Arthur",
      "Beowulf",
      "Gilgamesh",
      "Theseus",
      "Jason",
      "Siegfried"
    ],
    "Mythological Weapons": [
      "Mjolnir",
      "Excalibur",
      "Gungnir",
      "Sword of Damocles",
      "Aegis",
      "Trident of Poseidon",
      "Spear of Longinus",
      "Durandal",
      "Clarent",
      "Hrunting"
    ],
    "Historical Battles": [
      "Battle of Thermopylae",
      "Battle of Marathon",
      "Battle of Hastings",
      "Battle of Gettysburg",
      "Battle of Waterloo",
      "Battle of Actium",
      "Battle of Gaugamela",
      "Battle of Cannae",
      "Battle of Agincourt",
      "Battle of Stalingrad"
    ],
    "Famous Kings & Queens": [
      "Alexander the Great",
      "Cleopatra",
      "Julius Caesar",
      "Charlemagne",
      "Queen Elizabeth I",
      "Ramses II",
      "Catherine the Great",
      "King Solomon",
      "King Leonidas",
      "King Arthur"
    ],
    "Mythical Locations": [
      "Atlantis",
      "El Dorado",
      "Shangri-La",
      "Avalon",
      "Olympus",
      "Valhalla",
      "Camelot",
      "Asgard",
      "Hyperborea",
      "Tír na nÓg"
    ],
    "Ancient Philosophers": [
      "Socrates",
      "Plato",
      "Aristotle",
      "Confucius",
      "Laozi",
      "Epicurus",
      "Zeno of Citium",
      "Pythagoras",
      "Heraclitus",
      "Thales"
    ],
    "Forgotten Mesopotamian Gods": [
      "Enlil",
      "Anu",
      "Ninhursag",
      "Marduk",
      "Ashur",
      "Nergal",
      "Ereshkigal",
      "Inanna",
      "Utu",
      "Tiamat"
    ],
    "Obscure Norse Beings": [
      "Surtr",
      "Níðhöggr",
      "Ratatoskr",
      "Víðarr",
      "Máni",
      "Höðr",
      "Vör",
      "Álfr",
      "Fjorgyn",
      "Rán"
    ],
    "Mythical Inventions of Daedalus": [
      "Labyrinth of Crete",
      "Artificial Wings",
      "Mechanical Bull (for Pasiphae)",
      "Automatic Statues",
      "Bronze Man Talos",
      "Self-Moving Doors",
      "Moving Statues",
      "Wax Figures",
      "Musical Automatons",
      "Flaming Sword"
    ],
    "Celtic Deities of Nature": [
      "Cernunnos",
      "Brigid",
      "Arawn",
      "Lugh",
      "Danu",
      "Dagda",
      "Rhiannon",
      "Manannán mac Lir",
      "Epona",
      "Nuada"
    ],
    "Aztec Ritual Objects": [
      "Quetzal feather headdress",
      "Obsidian knife",
      "Chalchiuhtlicue's water vessels",
      "Xochipilli statues",
      "Huitzilopochtli's serpent staff",
      "Skull masks",
      "Turquoise serpent mosaic",
      "Tlazolteotl's woven cloth",
      "Tlaloc's rain jar",
      "Tezcatlipoca's obsidian mirror"
    ],
    "Byzantine Emperors Known for Controversy": [
      "Justinian II",
      "Andronikos I Komnenos",
      "Constantine IX Monomachos",
      "Michael III",
      "Basil II",
      "Constantine VI",
      "John V Palaiologos",
      "Leo III",
      "Alexios III Angelos",
      "Isaac II Angelos"
    ],
    "Forgotten Roman Engineering Marvels": [
      "Pont du Gard Aqueduct",
      "Cloaca Maxima",
      "Port of Ostia",
      "Sublacensian Aqueduct",
      "Trajan's Market",
      "Nero's Domus Aurea",
      "Pons Aemilius",
      "Aqua Appia",
      "Horreum of Leptis Magna",
      "Thermae of Caracalla"
    ],
    "Egyptian Funerary Amulets": [
      "Ankh Amulet",
      "Udjat Eye Amulet",
      "Djed Pillar Amulet",
      "Heart Scarab Amulet",
      "Shen Ring Amulet",
      "Sesen Lotus Amulet",
      "Ba Bird Amulet",
      "Tyt Knot Amulet",
      "Frog Amulet",
      "Papyrus Scepter Amulet"
    ],
    "Mythological Serpentine Creatures": [
      "Jörmungandr",
      "Python (Greek Myth)",
      "Naga (Hindu Myth)",
      "Basilisk (European Myth)",
      "Yamata no Orochi (Japanese Myth)",
      "Quetzalcoatl (Aztec Myth)",
      "Hydra (Greek Myth)",
      "Iormungand (Norse Myth)",
      "Vasuki (Hindu Myth)",
      "Apophis (Egyptian Myth)"
    ],
    "Historical Alchemical Symbols": [
      "Philosopher’s Stone",
      "Mercury (☿)",
      "Sulfur (🜍)",
      "Salt (🜔)",
      "Lead (♄)",
      "Gold (☉)",
      "Silver (☽)",
      "Antimony (🜇)",
      "Copper (♀)",
      "Iron (♂)"
    ],
  
  },

  "Psychology & Beliefs": {
    "Cognitive Biases": [
      "Confirmation Bias",
      "Anchoring Bias",
      "Hindsight Bias",
      "Availability Heuristic",
      "Dunning-Kruger Effect",
      "Self-Serving Bias",
      "Negativity Bias",
      "Sunk Cost Fallacy",
      "Ingroup Bias",
      "Framing Effect",
    ],
    "Conspiracy Theories": [
      "Area 51",
      "Moon Landing Hoax",
      "Flat Earth",
      "JFK Assassination",
      "9/11 Inside Job",
      "New World Order",
      "Illuminati",
      "Chemtrails",
      "Reptilian Overlords",
      "Pizzagate",
    ],
    "Philosophical Concepts": [
      "Existentialism",
      "Nihilism",
      "Utilitarianism",
      "Stoicism",
      "Determinism",
      "Dualism",
      "Absurdism",
      "Empiricism",
      "Idealism",
      "Relativism"
    ],
    "Popular Personality Theories": [
      "Freudian Psychoanalysis",
      "Jungian Archetypes",
      "Big Five Personality Traits",
      "Myers-Briggs Type Indicator (MBTI)",
      "Eysenck’s PEN Model",
      "Cattell’s 16 Personality Factors",
      "HEXACO Model",
      "Humanistic Theory",
      "Behaviorism",
      "Cognitive-Behavioral Theory"
    ],
    "Common Phobias": [
      "Arachnophobia (Fear of Spiders)",
      "Claustrophobia (Fear of Enclosed Spaces)",
      "Acrophobia (Fear of Heights)",
      "Agoraphobia (Fear of Open Spaces)",
      "Ophidiophobia (Fear of Snakes)",
      "Cynophobia (Fear of Dogs)",
      "Trypophobia (Fear of Holes)",
      "Nyctophobia (Fear of the Dark)",
      "Thanatophobia (Fear of Death)",
      "Aviophobia (Fear of Flying)"
    ],
    "Ethical Dilemmas": [
      "Trolley Problem",
      "Lifeboat Ethics",
      "Moral Relativism vs. Absolutism",
      "Euthanasia",
      "Capital Punishment",
      "Surveillance vs. Privacy",
      "Animal Rights",
      "Climate Change Responsibility",
      "Free Speech vs. Hate Speech",
      "Gene Editing"
    ],
    "Therapeutic Approaches": [
    "Cognitive Behavioral Therapy (CBT)",
    "Dialectical Behavior Therapy (DBT)",
    "Psychoanalysis",
    "Gestalt Therapy",
    "Humanistic Therapy",
    "Mindfulness-Based Cognitive Therapy (MBCT)",
    "Art Therapy",
    "Acceptance and Commitment Therapy (ACT)",
    "Exposure Therapy",
    "Narrative Therapy"
  ],
  "Altered States of Consciousness": [
    "Lucid Dreaming",
    "Hypnosis",
    "Meditation",
    "Flow State",
    "Out-of-Body Experience",
    "Near-Death Experience",
    "Psychedelic State",
    "Sleep Paralysis",
    "Trance",
    "Daydreaming"
  ],
  "Types of Intelligence": [
    "Linguistic Intelligence",
    "Logical-Mathematical Intelligence",
    "Spatial Intelligence",
    "Bodily-Kinesthetic Intelligence",
    "Musical Intelligence",
    "Interpersonal Intelligence",
    "Intrapersonal Intelligence",
    "Naturalistic Intelligence",
    "Emotional Intelligence (EQ)",
    "Fluid Intelligence"
  ],
  "Moral Development Theories": [
    "Kohlberg's Stages of Moral Development",
    "Piaget’s Theory of Moral Judgment",
    "Carol Gilligan's Ethics of Care",
    "Freud's Moral Conscience Theory",
    "Durkheim's Theory of Moral Regulation",
    "Erikson's Psychosocial Development Theory",
    "Bandura’s Social Learning Theory",
    "Vygotsky's Sociocultural Theory",
    "Hartshorne and May's Study on Character",
    "Social Intuitionist Model (Haidt)"
  ],
  "Types of Memory": [
    "Short-Term Memory",
    "Long-Term Memory",
    "Working Memory",
    "Episodic Memory",
    "Semantic Memory",
    "Procedural Memory",
    "Explicit Memory",
    "Implicit Memory",
    "Flashbulb Memory",
    "Sensory Memory"
  ],
  "Meditation Practices": [
    "Vipassana Meditation",
    "Transcendental Meditation",
    "Zen Meditation",
    "Metta (Loving Kindness) Meditation",
    "Mindfulness Meditation",
    "Kundalini Meditation",
    "Yoga Nidra",
    "Chakra Meditation",
    "Zazen (Sitting Meditation)",
    "Tonglen Meditation"
  ],
  "Dream Theories": [
    "Freudian Dream Theory",
    "Jungian Dream Analysis",
    "Activation-Synthesis Theory",
    "Problem-Solving Theory of Dreams",
    "Cognitive Dream Theory",
    "Threat Simulation Theory",
    "Memory Consolidation Theory",
    "Continuity Hypothesis of Dreaming",
    "Evolutionary Theory of Dreaming",
    "Reverse Learning Theory"
  ],
  },

  "Combat & Power": {
    "Guns": [
      "AK-47",
      "M16",
      "Glock 17",
      "Desert Eagle",
      "Colt 1911",
      "Remington 870",
      "Winchester Model 70",
      "Beretta 92FS",
      "Smith & Wesson Model 29",
      "FN SCAR",
    ],
    "Superpowers": [
      "Super Strength",
      "Telekinesis",
      "Invisibility",
      "Flight",
      "Time Manipulation",
      "Teleportation",
      "Super Speed",
      "Mind Control",
      "Invulnerability",
      "Shape-shifting",
    ],
    "Martial Arts Styles": [
      "Karate",
      "Brazilian Jiu-Jitsu",
      "Muay Thai",
      "Taekwondo",
      "Judo",
      "Krav Maga",
      "Kung Fu",
      "Aikido",
      "Capoeira",
      "Savate"
    ],
    "Medieval Weapons": [
      "Longsword",
      "Mace",
      "Warhammer",
      "Halberd",
      "Flail",
      "Crossbow",
      "Claymore",
      "Battle Axe",
      "Morning Star",
      "Poleaxe"
    ],
    "Military Vehicles": [
      "M1 Abrams Tank",
      "Humvee",
      "Apache Helicopter",
      "F-22 Raptor",
      "Leopard 2 Tank",
      "Black Hawk Helicopter",
      "Bradley Fighting Vehicle",
      "Challenger 2 Tank",
      "Patriot Missile System",
      "Eurofighter Typhoon"
    ],
    "Famous Swords": [
      "Excalibur",
      "Kusanagi",
      "Durandal",
      "Zulfiqar",
      "Masamune",
      "Joyeuse",
      "Sword of Goujian",
      "Hrunting",
      "Tizona",
      "Gram"
    ],
    "Famous Warriors": [
      "Spartacus",
      "Alexander the Great",
      "Genghis Khan",
      "Joan of Arc",
      "Leonidas",
      "Attila the Hun",
      "Sun Tzu",
      "Saladin",
      "William Wallace",
      "Hannibal Barca"
    ],
    "Combat Sports": [
      "Boxing",
      "Mixed Martial Arts (MMA)",
      "Kickboxing",
      "Wrestling",
      "Judo",
      "Brazilian Jiu-Jitsu",
      "Muay Thai",
      "Taekwondo",
      "Sambo",
      "Karate"
    ],
    "Mythical Weapons": [
      "Mjolnir",
      "Gungnir",
      "Trident of Poseidon",
      "Aegis Shield",
      "Spear of Longinus",
      "Hades' Bident",
      "Sword of Gryffindor",
      "Arondight",
      "Skofnung",
      "Fragarach"
    ],
    "Tactical Gear": [
      "Ballistic Helmet",
      "Bulletproof Vest",
      "Night Vision Goggles",
      "Tactical Gloves",
      "Combat Boots",
      "Tactical Belt",
      "Holster",
      "Tactical Flashlight",
      "Radio Communication Headset",
      "Body Armor Plates"
    ],
  },
};

export default categories;