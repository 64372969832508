import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import html2canvas from "html2canvas";
import Modal from "./Modal";
import categories from "./categories-meta";
import "./Homepage.css";
import Suggestion from "./suggestion";
import exit from "./exit.png";
import logo from "./logo-color.png";
import logoclear from "./logo-no-background.png";
import profilepic from "./defaultAvatar-removebg-preview.png";
import SearchBar from "./SearchBar"; // Import the SearchBar component

const Homepage = ({ auth }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tiers, setTiers] = useState({
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    F: [],
  });
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const [rankedOptions, setRankedOptions] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [completedCategories, setCompletedCategories] = useState([]);
  const [customCategories, setCustomCategories] = useState({});
  const [mostRankedCategories, setMostRankedCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(categories); // State for filtered categories

  const nextOptionRef = useRef(null);
  const selectionMenuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.currentUser;
  const db = getFirestore();

  useEffect(() => {
    if (!user) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [user]);

  useEffect(() => {
    console.log("Location State:", location.state); // Check the full state object

    if (location.state?.sharedCategory && location.state?.sharedOptions) {
      console.log("Shared Category:", location.state.sharedCategory); // Verify category
      console.log("Shared Options:", location.state.sharedOptions); // Verify options
      handleCategorySelect(
        location.state.sharedCategory,
        location.state.sharedOptions
      );
      setShowModal(false); // Ensure the modal doesn't show
    }
  }, [location.state]);

  const handleCategorySelect = (category, options) => {
    console.log("Selected Category:", category); // Confirm the correct category
    console.log("Selected Options:", options); // Confirm the correct options

    setSelectedCategory(category);
    setSelectedOptions(options);
    setTiers({ S: [], A: [], B: [], C: [], D: [], F: [] });
    setCurrentOptionIndex(0);
    setRankedOptions([]);
    setStartTime(Date.now());
    setEndTime(null);
    setShowModal(false); // Close the modal when a category is selected
  };

  useEffect(() => {
    const fetchCategories = async () => {
      if (user) {
        try {
          const rankingsCollection = collection(
            db,
            "rankings",
            user.uid,
            "userRankings"
          );
          const rankingsSnapshot = await getDocs(rankingsCollection);
          const completed = rankingsSnapshot.docs.map((doc) => doc.id);
          setCompletedCategories(completed);

          const customCategoriesCollection = collection(
            db,
            "customCategories",
            user.uid,
            "userCategories"
          );
          const customCategoriesSnapshot = await getDocs(
            customCategoriesCollection
          );
          const customCategoriesData = customCategoriesSnapshot.docs.reduce(
            (acc, doc) => ({
              ...acc,
              [doc.id]: {
                options: Array.isArray(doc.data().options)
                  ? doc.data().options
                  : [],
                customCategoryName:
                  doc.data().customCategoryName || "Unnamed Category",
              },
            }),
            {}
          );
          setCustomCategories(customCategoriesData);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };
    fetchCategories();
  }, [user, db]);

  useEffect(() => {
    if (selectedCategory && selectionMenuRef.current) {
      selectionMenuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCategory]);

  const addCategoryWithOptions = async (
    newCategory = "Unnamed Category",
    options
  ) => {
    if (user) {
      try {
        const customCategoryRef = doc(
          collection(db, "customCategories", user.uid, "userCategories"),
          newCategory
        );
        await setDoc(customCategoryRef, {
          customCategoryName: newCategory,
          options,
        });
        setCustomCategories((prev) => ({
          ...prev,
          [newCategory]: { options, customCategoryName: newCategory },
        }));
      } catch (error) {
        console.error("Error saving custom category:", error);
      }
    }
  };

  useEffect(() => {
    const fetchMostRankedCategories = async () => {
      try {
        const rankingsCollection = collection(db, "rankings");
        const rankingsSnapshot = await getDocs(rankingsCollection);
        const categoryCount = {};

        rankingsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (categoryCount[data.category]) {
            categoryCount[data.category]++;
          } else {
            categoryCount[data.category] = 1;
          }
        });

        const sortedCategories = Object.keys(categoryCount).sort(
          (a, b) => categoryCount[b] - categoryCount[a]
        );

        // Set default categories if no data is fetched
        if (sortedCategories.length === 0) {
          const defaultCategories = [
            "Default Category 1",
            "Default Category 2",
            "Default Category 3",
          ];
          setMostRankedCategories(defaultCategories);

          // Save default categories to the database
          const batch = db.batch();
          defaultCategories.forEach((category) => {
            const docRef = doc(rankingsCollection, category);
            batch.set(docRef, { category, count: 1 });
          });
          await batch.commit();
        } else {
          setMostRankedCategories(sortedCategories.slice(0, 10));
        }
      } catch (error) {
        console.error("Error fetching most ranked categories:", error);
        // Set default categories in case of an error
        setMostRankedCategories([
          "Default Category 1",
          "Default Category 2",
          "Default Category 3",
        ]);
      }
    };

    fetchMostRankedCategories();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/auth");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  const moveOptionToTier = (option, tier) => {
    console.log("Moving option:", option, "to tier:", tier); // Verify tier movement

    setTiers((prevTiers) => {
      const newTiers = { ...prevTiers };
      // Remove the option from all tiers and add it to the selected tier
      Object.keys(newTiers).forEach((key) => {
        newTiers[key] = newTiers[key].filter((item) => item !== option);
      });
      newTiers[tier].push(option); // Add the option to the selected tier
      return newTiers;
    });

    setRankedOptions((prev) => [
      ...prev.filter((item) => item.option !== option),
      { option, tier },
    ]);

    setCurrentOptionIndex((prev) => {
      const newIndex = prev + 1;
      console.log(
        "New Index:",
        newIndex,
        "Total Options:",
        selectedOptions.length
      );
      if (newIndex === selectedOptions.length) {
        setEndTime(Date.now());
        handleRankingComplete();
      }
      return newIndex;
    });
  };

  const handleRankingComplete = async () => {
    console.log("Ranking complete for:", selectedCategory);
    setCompletedCategories((prev) => [...prev, selectedCategory]);

    // Find the meta-category from the categories-meta.js file
    let metaCategory = null;
    for (let key in categories) {
      if (categories[key][selectedCategory]) {
        metaCategory = key; // Set the meta-category (e.g., "Beverages", "Technology & Software")
        break;
      }
    }

    if (user) {
      const rankingData = {
        category: selectedCategory,
        tiers,
        timestamp: new Date(),
        customCategoryName:
          customCategories[selectedCategory]?.customCategoryName || null,
        metaCategory: metaCategory || "Default MetaCategory", // Use the derived meta-category
      };

      try {
        const userRankingsRef = doc(
          collection(db, "rankings", user.uid, "userRankings"),
          selectedCategory
        );
        await setDoc(userRankingsRef, rankingData);
        console.log("Ranking saved successfully!");
      } catch (error) {
        console.error("Error saving ranking:", error);
      }
    }
  };

  const handleDownloadImage = () => {
    const element = document.getElementById("tier-list");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "tier-list.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const shareCategory = async (category, recipientUsername) => {
    if (!recipientUsername) return;

    const message = {
      from: user.uid,
      category: category.name, // Ensure 'category' and 'options' are both correctly passed
      options: category.options, // Make sure this exists
      timestamp: new Date(),
    };

    try {
      const recipientDoc = await getDoc(
        doc(db, "usernames", recipientUsername)
      );
      if (!recipientDoc.exists()) {
        throw new Error("Recipient username does not exist");
      }
      const recipientId = recipientDoc.data().userId;

      const inboxRef = doc(collection(db, "inbox", recipientId, "messages"));
      await setDoc(inboxRef, message);
      alert("Category shared successfully!");
    } catch (error) {
      console.error("Error sharing category:", error);
      alert("Failed to share category.");
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = {};
    Object.keys(categories).forEach((metaCategory) => {
      const subCategories = Object.keys(categories[metaCategory]).filter(
        (subCategory) =>
          subCategory.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (subCategories.length > 0) {
        filtered[metaCategory] = subCategories.reduce((acc, subCategory) => {
          acc[subCategory] = categories[metaCategory][subCategory];
          return acc;
        }, {});
      }
    });
    setFilteredCategories(filtered);
  };

  return (
    <div style={{width:"100%"}}>
      <div className="banner">
      <button className="exit-button" onClick={handleSignOut}>
          <img src={exit} alt="Exit" className="exit-icon" />
        </button>
      {user && !user.isAnonymous ? (
        <Link to="/profile">
          <img src={logo} alt="Ranker Logo" className="logo" />
        </Link>
      ) : (
        <img src={logo} alt="Ranker Logo" className="logo" />
      )}
        <SearchBar categories={categories} onSearch={handleSearch} /> {/* Add SearchBar */}
        <img src={profilepic} alt="Profile" className="profile-pic" />
      </div>
      <div style={{display:"flex", justifyContent:"center", background:"rgba(0,0,0,0.05)"}}>
      <img src={logoclear} style={{width:'250px', padding:'5px'}} />
      </div>
      <div className="header">
        <span className="username">
          {user
            ? `Hello, ${user.isAnonymous ? "Guest" : user.displayName}`
            : ""}
        </span>
        {!user.isAnonymous && <Link to="/profile" className="profile-link">Go to Profile</Link>}

        
      </div>

      {/* <div className="streaming-banner">
        {mostRankedCategories.map((category, index) => (
          <span key={index} className="streaming-text">
            {category}
          </span>
        ))}
      </div> */}

      <div className="homepage-container">
      <div className="categories-section">
  <div className="categories">
    <h2>Select a Category</h2>
    {/* Loop through all meta-categories */}
    {Object.keys(filteredCategories).map((metaCategory) => (
      <div key={metaCategory} className="meta-category">
        <h3>{metaCategory}</h3> {/* Display the meta-category header */}
        {/* Loop through sub-categories */}
        {Object.keys(filteredCategories[metaCategory]).map((subCategory) => (
          <button
            key={subCategory}
            onClick={() =>
              handleCategorySelect(
                subCategory,
                filteredCategories[metaCategory][subCategory]
              )
            }
            disabled={completedCategories.includes(subCategory)}
            className={
              completedCategories.includes(subCategory)
                ? "disabled-category"
                : ""
            }
          >
            {subCategory} {/* Display the sub-category */}
          </button>
        ))}
      </div>
    ))}
  </div>
</div>
        <div className="custom-categories">
          <h2>Your Custom Categories</h2>
          {Object.keys(customCategories).map((category) => (
            <button
              key={category}
              onClick={() =>
                handleCategorySelect(
                  category,
                  customCategories[category].options
                )
              }
              className={
                completedCategories.includes(category)
                  ? "disabled-category"
                  : ""
              }
            >
              {category}
            </button>
          ))}
        </div>

        <Suggestion addCategoryWithOptions={addCategoryWithOptions} />

        {selectedCategory && (
          <>
            <h2 ref={selectionMenuRef}>{selectedCategory}</h2>
            <div className="options">
              <h3>Options</h3>
              <ul>
                {selectedOptions.map(
                  (option, index) =>
                    index <= currentOptionIndex && (
                      <li
                        key={option}
                        ref={
                          index === currentOptionIndex ? nextOptionRef : null
                        }
                      >
                        {option}
                        <div className="buttons">
                          {Object.keys(tiers).map((tier) => (
                            <button
                              key={tier}
                              onClick={() => moveOptionToTier(option, tier)}
                              disabled={index < currentOptionIndex}
                            >
                              {tier}
                            </button>
                          ))}
                        </div>
                      </li>
                    )
                )}
              </ul>
            </div>

            <div id="tier-list" className="tiers">
              {Object.keys(tiers).map((tier) => (
                <div key={tier} className="tier">
                  <h3>Tier {tier}</h3>
                  <ul>
                    {tiers[tier].map((option) => (
                      <li key={option}>{option}</li>
                    ))}
                  </ul>
                </div>
              ))}
              <div className="footer">
                <p>Category: {selectedCategory}</p>
                {endTime && (
                  <p>Time Taken: {formatTime(endTime - startTime)}</p>
                )}
              </div>
            </div>

            <button id="download-button" onClick={handleDownloadImage}>
              Download Image
            </button>
          </>
        )}

        <Modal show={showModal} onClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default Homepage;