import React, { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import "./UserProfile.css";
import Community from "./Community"; // Import the new component
import DataHub from "./DataHub";

const UserProfile = () => {
  const [rankings, setRankings] = useState([]);
  const [customCategories, setCustomCategories] = useState([]);
  const [inbox, setInbox] = useState([]);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [isGuest, setIsGuest] = useState(false); // Add state for guest check
  const [collapsedCategories, setCollapsedCategories] = useState({}); // State to manage collapsed categories
  const [socialMode, setSocialMode] = useState(false); // State for social mode
  const [isDataHubVisible, setIsDataHubVisible] = useState(false);

  const auth = getAuth();
  const db = getFirestore();
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    // Check if the user is a guest
    setIsGuest(user.isAnonymous);

    const fetchData = async () => {
      try {
        const userDocRef = await doc(db, "usernames", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const storedUsername = userDoc.data().username;
          setUsername(storedUsername);
          console.log(
            `Username "${storedUsername}" pulled successfully from Firestore.`
          );
        } else if (user.isAnonymous) {
          setUsername("Guest");
        }

        // Fetch custom categories
        const customCategoriesSnapshot = await getDocs(
          collection(db, "customCategories", user.uid, "userCategories")
        );
        const customCategories = customCategoriesSnapshot.docs.map((doc) => ({
          name: doc.id,
          options: doc.data().options || [],
          customCategoryName:
            doc.data().customCategoryName || "Unnamed Category",
        }));

        // Fetch meta-categories
        const metaCategoriesSnapshot = await getDocs(
          collection(db, "metaCategories")
        );
        const metaCategories = metaCategoriesSnapshot.docs.map((doc) => ({
          name: doc.id,
          options: doc.data().options || [],
          customCategoryName:
            doc.data().customCategoryName || "Unnamed Meta Category",
        }));

        // Combine custom categories and meta-categories
        const combinedCategories = [...customCategories, ...metaCategories];

        const fetchedRankings = await fetchRankings();
        const fetchedInbox = await fetchInbox();

        setCustomCategories(combinedCategories);
        setRankings(fetchedRankings);
        setInbox(fetchedInbox);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, db]);

  useEffect(() => {
    document.querySelectorAll(".toc-list a").forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  const fetchRankings = async () => {
    const snapshot = await getDocs(
      collection(db, "rankings", user.uid, "userRankings")
    );
    return snapshot.docs.map((doc) => ({
      category: doc.data().category,
      tiers: doc.data().tiers,
      customCategoryName: doc.data().customCategoryName || doc.data().category,
    }));
  };

  const fetchInbox = async () => {
    const snapshot = await getDocs(
      collection(db, "inbox", user.uid, "messages")
    );
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const saveCategory = async (category) => {
    try {
      await setDoc(
        doc(db, "customCategories", user.uid, "userCategories", category.name),
        {
          options: category.options,
          customCategoryName: category.customCategoryName || "Unnamed Category",
        }
      );
      alert("Category saved successfully!");
    } catch (error) {
      console.error("Error saving category:", error);
      alert("Failed to save category.");
    }
  };

  const saveRanking = async (ranking) => {
    try {
      await setDoc(
        doc(db, "rankings", user.uid, "userRankings", ranking.category),
        {
          category: ranking.category,
          tiers: ranking.tiers,
          customCategoryName: ranking.customCategoryName || ranking.category,
        }
      );
      alert("Ranking saved successfully!");
    } catch (error) {
      console.error("Error saving ranking:", error);
      alert("Failed to save ranking.");
    }
  };

  const shareCategory = async (category, recipientUsername) => {
    if (!recipientUsername) {
      alert("Recipient username is required.");
      return;
    }

    const message = {
      from: user.displayName, // Using the current user's username
      category: {
        name: category.name,
        options: category.options,
        customCategoryName: category.customCategoryName || "Unnamed Category",
      },
      timestamp: new Date(),
    };

    try {
      console.log("Recipient Username:", recipientUsername);

      if (typeof recipientUsername !== "string") {
        throw new Error("Recipient username must be a string.");
      }

      // Query Firestore to get the recipient's userId by their username
      const userQuery = query(
        collection(db, "usernames"),
        where("displayName", "==", recipientUsername)
      );

      // Fetch the query results
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const recipientId = userDoc.data().userId;

        if (!recipientId) {
          throw new Error("User ID is undefined.");
        }

        // Save the message to the recipient's inbox
        const inboxRef = doc(collection(db, "inbox", recipientId, "messages"));
        await setDoc(inboxRef, message);

        alert("Category shared successfully via username!");
      } else {
        alert("Username not found.");
      }
    } catch (error) {
      console.error("Error sharing category:", error);
      alert("Failed to share category.");
    }
  };

  const handleInboxItemClick = (category, options) => {
    console.log("Category:", category);
    console.log("Options:", options);
    navigate("/", {
      state: { sharedCategory: category, sharedOptions: options },
    });
  };

  const isCategoryRanked = (categoryName) => {
    return rankings.some((ranking) => ranking.category === categoryName);
  };

  const handleDeleteInboxMessage = async (messageId) => {
    try {
      await deleteDoc(doc(db, "inbox", user.uid, "messages", messageId));
      setInbox(inbox.filter((message) => message.id !== messageId));
    } catch (error) {
      console.error("Error deleting message:", error);
      alert("Failed to delete message.");
    }
  };

  const renderInbox = (messages) => (
    <div className={`inbox-grid ${isGuest ? "guest-disabled" : ""}`}>
      {messages.map((message, index) => {
        const isRanked = isCategoryRanked(message.category.name);
        return (
          <div
            key={index}
            className={`inbox-message ${isRanked ? "ranked" : ""}`}
            onClick={() =>
              !isRanked &&
              handleInboxItemClick(
                message.category.name,
                message.category.options
              )
            }
          >
            <h3>{message.category.name || "Unnamed Category"}</h3>
            <p>Shared by: {message.from}</p>
            <button
              className="delete-button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent click event
                handleDeleteInboxMessage(message.id);
              }}
              disabled={isGuest}
            >
              Delete
            </button>
          </div>
        );
      })}
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const deleteCategory = async (categoryName) => {
    try {
      await deleteDoc(
        doc(db, "customCategories", user.uid, "userCategories", categoryName)
      );
      setCustomCategories(
        customCategories.filter((category) => category.name !== categoryName)
      );
      alert("Category deleted successfully!");
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Failed to delete category.");
    }
  };

  const toggleCategoryCollapse = (categoryName) => {
    setCollapsedCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  const toggleDataHub = () => {
    setIsDataHubVisible(!isDataHubVisible);
  };

  const renderCategoryList = (categories) => (
    <div className="inventory-container">
      <div className="inventory-scroll-container">
        <div className="inventory-grid">
          {categories.map((category, index) => (
            <div key={index} className="inventory-item">
              <div className="inventory-header">
                <h3
                  className="inventory-category-title"
                  onClick={() => toggleCategoryCollapse(category.name)}
                >
                  {category.customCategoryName}
                </h3>
              </div>
              {!collapsedCategories[category.name] && (
                <ul className="inventory-options-list">
                  {category.options.map((option, optionIndex) => (
                    <li key={optionIndex} className="inventory-option">
                      {option}
                    </li>
                  ))}
                </ul>
              )}
              <div className="inventory-actions">
                <input
                  type="text"
                  placeholder="Recipient's username"
                  className="inventory-share-input"
                  id={`recipient-${index}`}
                  disabled={isGuest}
                />
                <div className="inventory-buttons">
                  <button
                    onClick={() => deleteCategory(category.name)}
                    className="delete-button"
                    disabled={isGuest}
                  >
                    Delete
                  </button>
                  <button
                    onClick={() =>
                      shareCategory(
                        category,
                        document.getElementById(`recipient-${index}`).value
                      )
                    }
                    className="share-button"
                    disabled={isGuest}
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderRankings = (rankings) => {
    const tierOrder = ["S", "A", "B", "C", "D", "F"];

    return (
      <div className="main-content" style={{ width: "100%" }}>
        <div className="netflix-scrolling-container">
          {rankings.map((ranking, index) => (
            <div
              key={index}
              className="ranking-card"
              id={`ranking${index + 1}`}
            >
              <h3 className="ranking-title">{ranking.customCategoryName}</h3>
              {tierOrder.map((tier) => (
                <div key={tier} className={`tier tier-${tier}`}>
                  <h4>Tier {tier}</h4>
                  <ul>
                    {ranking.tiers[tier]?.map((option, optionIndex) => (
                      <li key={optionIndex}>{option}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const toggleSocialMode = () => {
    setSocialMode(!socialMode);
  };

  return (
    <div className="user-profile">
      <div className="side-menu">
        <div className="username-badge">
          <h2>{user.displayName}</h2>
          <p>Rankings: {rankings.length}</p>
        </div>

        <nav className="side-menu-nav">
          <Link to="/" className="side-menu-link">
            Home
          </Link>
          <a
            href="#inbox"
            className="side-menu-link"
            onClick={(e) => {
              e.preventDefault();
              document
                .getElementById("inbox")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Inbox
          </a>
          <a
            href="#custom-categories"
            className="side-menu-link"
            onClick={(e) => {
              e.preventDefault();
              document
                .getElementById("custom-categories")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Custom Categories
          </a>
          <a
            href="#rankings"
            className="side-menu-link"
            onClick={(e) => {
              e.preventDefault();
              document
                .getElementById("rankings")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Rankings
          </a>
        </nav>
      </div>
      <div className="main-content-data">
        <div className="main-content" style={{ width: "100%" }}>
          <header className="profile-header">
            <h1>{user ? `${user.displayName}'s Profile` : "User Profile"}</h1>
            <Link to="/" className="back-to-homepage">
              Back to Homepage
            </Link>
          </header>
          <div className="inbox-friends-section">
            <div id="inbox" className="inbox-section">
              <h2>Your Inbox</h2>
              <p>Click to rank each category</p>

              {inbox.length === 0 ? (
                <p>Your inbox is empty.</p>
              ) : (
                renderInbox(inbox)
              )}
            </div>
            <Community /> {/* Render the Community component */}
          </div>

          <h2 id="custom-categories">Your Custom Categories</h2>
          {customCategories.length === 0 ? (
            <p>You haven't created any custom categories yet.</p>
          ) : (
            renderCategoryList(customCategories)
          )}
          <h2 id="rankings">Your Rankings</h2>
          {rankings.length === 0 ? (
            <p>You haven't ranked any categories yet.</p>
          ) : (
            <>
              <ul className="toc-list">
                {rankings.map((ranking, index) => (
                  <li key={index}>
                    <a href={`#ranking${index + 1}`}>
                      {ranking.customCategoryName}
                    </a>
                  </li>
                ))}
              </ul>
              {renderRankings(rankings)}
            </>
          )}
        </div>
        {/* <button className="hamburger-button" onClick={toggleDataHub}>
          &#9776;
        </button> */}
        <div className={`right-datahub ${isDataHubVisible ? "visible" : ""}`}>
          {user && (
            <div className="datahub-container">
              <DataHub user={user} /> {/* Render the DataHub component */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
