import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginModal.css';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function LoginModal({ auth, setUser, show, onClose, setSuccessMessage, setError }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      // Fetch the user ID associated with the username
      const usernameDoc = await getDoc(doc(db, 'usernames', username));
      if (!usernameDoc.exists()) {
        throw new Error('Username does not exist');
      }
      const userId = usernameDoc.data().userId;

      // Use the user ID to sign in with a dummy email
      const userCredential = await signInWithEmailAndPassword(auth, `${username}@example.com`, password);
      if (userCredential.user.uid !== userId) {
        throw new Error('Invalid username or password');
      }

      setUser(userCredential.user);
      setSuccessMessage('Login successful! Redirecting...'); // Show success message
      onClose(); // Close the modal on successful login
      navigate('/'); // Redirect to home
    } catch (error) {
      console.error("Error logging in:", error.message);
      setError("Error logging in: " + error.message); // Display error message in UI
    }
  };

  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default LoginModal;