import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInAnonymously, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import './AuthForm.css'; // Import the CSS file
import logocolor from './logo-color.png'; // Import the logo
import LoginModal from './LoginModal'; // Import the LoginModal component
import { color } from 'chart.js/helpers';

function AuthForm({ auth, setUser }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false); // Modal state
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [error, setError] = useState(''); // State to store error messages
  const navigate = useNavigate(); // Initialize useNavigate
  const db = getFirestore();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      // Check if username already exists
      const usernameDoc = await getDoc(doc(db, 'usernames', username));
      if (usernameDoc.exists()) {
        throw new Error('Username already taken');
      }

      // Create user with email and password (using a dummy email)
      const userCredential = await createUserWithEmailAndPassword(auth, `${username}@example.com`, password);
      const user = userCredential.user;
      // Set the display name to the username
      await updateProfile(user, { displayName: username });

      // Save username to Firestore
      await setDoc(doc(db, 'usernames', username), { userId: user.uid, displayName: username });

      setUser(user);
      setSuccessMessage('Sign-up successful! You are now logged in.'); // Show success message
      navigate('/'); // Redirect to home after successful sign-up
    } catch (error) {
      console.error("Error creating user:", error.message);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(error.message)) {
        setError("Please use a username"); // Set custom error message for email errors
      } else if (error.code === "auth/invalid-email") {
        setError("No emails"); // Set custom error message for email errors
      } else {
        setError(error.message); // Set original error message for other errors
      }
    }
  };

  const handleGuestLogin = async () => {
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      const userCredential = await signInAnonymously(auth);
      setUser(userCredential.user);
      setSuccessMessage('Logged in as guest! Redirecting...'); // Show success message
      navigate('/'); // Redirect to home
    } catch (error) {
      console.error("Error logging in as guest:", error.message);
      setError("Error logging in as guest: " + error.message); // Display error message
    }
  };

  return (
    <div className="auth-form-container">
      <img src={logocolor} style={{width:'250px'}}alt="logo" />
      <h2>Sign Up</h2>
      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Sign Up</button>
      </form>

      <button onClick={() => setShowLoginModal(true)}>Login</button>
      <button className="guest-button" onClick={handleGuestLogin}>Join as Guest</button>

      {/* Display error or success messages */}
      {error && <p className="error">{error}</p>}
      {successMessage && <p className="success">{successMessage}</p>}

      {/* Show the Login Modal when the login button is clicked */}
      <LoginModal
        auth={auth}
        setUser={setUser}
        show={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        setSuccessMessage={setSuccessMessage} // Pass down success message state
        setError={setError} // Pass down error state
      />
    </div>
  );
}

export default AuthForm;