import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  getDoc,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "./Community.css"; // Ensure you have appropriate styles
import defaultAvatar from "./defaultAvatar.jpeg"; // Path to your default avatar

const Community = () => {
  const [queryText, setQueryText] = useState("");
  const [results, setResults] = useState([]);
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFriend, setSelectedFriend] = useState(null); // Track selected friend for deletion
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal state
  const [fadeOut, setFadeOut] = useState(false);

  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      fetchFriends();
      fetchFriendRequests();
    }
  }, [user]);

  useEffect(() => {
    if (confirmationMessage || errorMessage) {
      setFadeOut(false);
      const timer = setTimeout(() => {
        setFadeOut(true);
        const fadeTimer = setTimeout(() => {
          setConfirmationMessage("");
          setErrorMessage("");
        }, 500); // 0.5 seconds delay for fade out
        return () => clearTimeout(fadeTimer);
      }, 3000); // 3 seconds delay
      return () => clearTimeout(timer);
    }
  }, [confirmationMessage, errorMessage]);

  useEffect(() => {
    if (user) {
      const requestsRef = collection(db, "usernames", user.displayName, "friendRequests");
      const unsubscribe = onSnapshot(requestsRef, (snapshot) => {
        const requestsList = snapshot.docs.map((doc) => doc.data());
  
        // Optional: filter out accepted requests
        const filteredRequests = requestsList.filter((request) => {
          return !friends.some((friend) => friend.username === request.from);
        });
  
        setFriendRequests(filteredRequests);
      });
  
      // Clean up the listener
      return () => unsubscribe();
    }
  }, [user, friends]);

  const fetchFriends = async () => {
    try {
      const friendsRef = collection(db, "usernames", user.displayName, "friends");
      const snapshot = await getDocs(friendsRef);
      const friendsList = snapshot.docs.map((doc) => doc.data());
      setFriends(friendsList);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      const requestsRef = collection(db, "usernames", user.displayName, "friendRequests");
      const snapshot = await getDocs(requestsRef, { source: 'server' });
      const requestsList = snapshot.docs.map((doc) => doc.data());    // Make sure to filter out accepted requests
      const filteredRequests = requestsList.filter((request) => {
        return !friends.some((friend) => friend.username === request.from);
      });
  
      setFriendRequests(filteredRequests);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  const getUsername = async () => {
    if (user.displayName) {
      return user.displayName;
    }
    try {
      const userDoc = await getDoc(doc(db, "usernames", user.displayName));
      if (userDoc.exists()) {
        return userDoc.data().username;
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
    return "Unknown User"; // Fallback in case no username is found
  };

  const handleSendFriendRequest = async (username) => {
    if (!username) {
      setErrorMessage("Invalid username: Username is undefined or empty.");
      console.error("Invalid username: Username is undefined or empty.");
      return;
    }
  
    // Check if the username is already a friend
    if (friends.some((friend) => friend.username === username)) {
      setConfirmationMessage(`${username} is already your friend.`);
      return;
    }
  
    try {
      const usernamesRef = collection(db, "usernames");
      const q = query(usernamesRef, where("displayName", "==", username));
      const snapshot = await getDocs(q);
  
      if (!snapshot.empty) {
        const recipientDoc = snapshot.docs[0];
        const recipientUserId = recipientDoc.data().userId;
  
        if (!recipientUserId) {
          setErrorMessage("Could not find the recipient's user ID.");
          console.error("Recipient user ID is undefined.");
          return;
        }
  
        const senderUsername = await getUsername();
        const requestDocRef = doc(db, "usernames", username, "friendRequests", user.uid);
        await setDoc(requestDocRef, {
          from: user.displayName,
          username: senderUsername,
        });
  
        setConfirmationMessage(`Friend request sent to ${username}`);
        console.log("Friend request sent to:", username);
      } else {
        setErrorMessage("User not found");
        console.error("User not found");
      }
    } catch (error) {
      setErrorMessage("Error sending friend request");
      console.error("Error sending friend request:", error);
    }
  };

  const handleAcceptFriendRequest = async (request) => {
    try {
      if (friends.some((friend) => friend.username === request.username)) {
        setConfirmationMessage(`${request.username} is already your friend.`);
        return;
      }
  
      const friendDocRef = doc(db, "usernames", user.displayName, "friends", request.from);
      await setDoc(friendDocRef, {
        username: request.username,
        avatarUrl: request.avatarUrl || defaultAvatar,
      });
  
      const reciprocalFriendDocRef = doc(db, "usernames", request.from, "friends", user.displayName);
      const currentUserAvatar = user.photoURL || defaultAvatar;
      await setDoc(reciprocalFriendDocRef, {
        username: user.displayName,
        avatarUrl: currentUserAvatar,
      });
  
      const requestDocRef = doc(db, "usernames", user.displayName, "friendRequests", request.from);
      await deleteDoc(requestDocRef);
  
      setFriends((prevFriends) => [
        ...prevFriends,
        { username: request.username, avatarUrl: request.avatarUrl || defaultAvatar },
      ]);
      setFriendRequests((prevRequests) => prevRequests.filter((req) => req.from !== request.from));
  
      setConfirmationMessage(`You are now friends with ${request.username}`);
      console.log(`Friend request from ${request.username} accepted.`);
    } catch (error) {
      setErrorMessage("Error accepting friend request.");
      console.error("Error accepting friend request:", error);
    }
  };
  
  const handleRemoveFriendRequest = async (request) => {
    try {
      const requestDocRef = doc(db, "usernames", user.displayName, "friendRequests", request.from);
      await deleteDoc(requestDocRef);

      const reciprocalFriendReqDocRef = doc(db, "usernames", request.from, "friendRequests", user.uid);
      await deleteDoc(reciprocalFriendReqDocRef);
  
      setFriendRequests((prevRequests) => prevRequests.filter((req) => req.from !== request.from));
  
      setConfirmationMessage(`Friend request from ${request.username} has been removed.`);
      console.log(`Friend request from ${request.username} removed.`);
    } catch (error) {
      setErrorMessage("Error removing friend request.");
      console.error("Error removing friend request:", error);
    }
  };

  const handleShowDeleteModal = (friend) => {
    setSelectedFriend(friend);
    setShowDeleteModal(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!queryText.trim()) {
      setResults([]);
      return;
    }

    try {
      const usersRef = collection(db, "usernames");
      const q = query(
        usersRef,
        where("displayName", ">=", queryText),
        where("displayName", "<=", queryText + "\uf8ff")
      );
      const snapshot = await getDocs(q);
      const searchResults = snapshot.docs.slice(0, 4).map((doc) => doc.data()); // Limit to 4 results

      setResults(searchResults);
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorMessage("Error fetching users");
    }
  };

  const handleRemoveFriend = async () => {
    try {
      const friend = selectedFriend;
      const friendDocRef = doc(db, "usernames", user.displayName, "friends", friend.username);
      await deleteDoc(friendDocRef);

      const reciprocalFriendDocRef = doc(db, "usernames", friend.username, "friends", user.displayName);
      await deleteDoc(reciprocalFriendDocRef);

      setFriends((prevFriends) =>
        prevFriends.filter((f) => f.username !== friend.username)
      );

      setConfirmationMessage(`You have removed ${friend.username}`);
      setShowDeleteModal(false);
      setSelectedFriend(null);
    } catch (error) {
      setErrorMessage("Error removing friend");
      console.error("Error removing friend:", error);
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setSelectedFriend(null);
  };

  return (
    <div className="community panel">
      
        <form onSubmit={handleSearch}>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height: '100px'}}>
          <input
            type="text"
            value={queryText}
            onChange={(e) => setQueryText(e.target.value)}
            placeholder="Search for a username"
          />
          <button type="submit" style={{ height:'35px', borderRadius:'15px',marginLeft:'10px', marginBottom:'12px'}}>Search</button>
          </div>
        </form>
      
      <div className="results">
        {results.length === 0 ? (
          <p>No results found</p>
        ) : (
          results.map((user, index) => (
            <div key={index} className="result-item">
              <img
                src={user.avatarUrl || defaultAvatar}
                alt={`${user.username}'s avatar`}
                className="avatar-small"
              />
              <p>Username: {user.displayName}</p>
              <button onClick={() => handleSendFriendRequest(user.displayName)}>
                Send Friend Request
              </button>
            </div>
          ))
        )}
      </div>
      {confirmationMessage && (
        <div className="confirmation-message">{confirmationMessage}</div>
      )}
      {errorMessage && (
        <div className={`error-message ${fadeOut ? "fade-out" : ""}`}>
          {errorMessage}
        </div>
      )}
      <h2>Your Friends</h2>
      {friends.length === 0 ? (
        <p className="no-friends">You have no friends added.</p>
      ) : (
        <div className="friends-grid">
          {friends.map((friend, index) => (
            <div key={index} className="friend-item">
              <img
                src={friend.avatarUrl || defaultAvatar}
                alt={`${friend.username}'s avatar`}
                className="avatar"
              />
              <p>{friend.username}</p>
              <button
                className="delete-button"
                onClick={() => handleShowDeleteModal(friend)}
              >
                remove
              </button>
            </div>
          ))}
        </div>
      )}
      <h2>Friend Requests</h2>
      {friendRequests.length === 0 ? (
        <p className="no-requests">You have no friend requests.</p>
      ) : (
        <div className="requests-grid">
          {friendRequests.map((request, index) => (
            <div key={index} className="request-item">
              <img
                src={request.avatarUrl || defaultAvatar}
                alt={`${request.username}'s avatar`}
                className="avatar"
              />
              <p>{request.username}</p>
              <button onClick={() => handleAcceptFriendRequest(request)}>
                Accept
              </button>
              <button onClick={() => handleRemoveFriendRequest(request)}>
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to remove {selectedFriend.username}?</p>
            <button className="confirm-button" onClick={handleRemoveFriend}>
              Yes
            </button>
            <button className="cancel-button" onClick={handleCloseModal}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Community;